import React, { useCallback } from 'react';
import useGuarantorStore from '../../state/Guarantor.store';
import { useTranslation } from '@lendsqr/lingua-react';

const GuarantorError = () => {
  const { translate } = useTranslation();
  const [errorMessage] = useGuarantorStore(
    useCallback((s) => [s.errorMessage], [])
  );

  return (
    <div className="validate_success" id="approve-failed">
      <div className="icon">
        <img src="/failed.svg" />
      </div>
      <h5 className="validate_form-header">
        {translate('verification-web-app-request-failed')}
      </h5>
      <p className="validate_form-sub">{errorMessage}</p>
    </div>
  );
};

export { GuarantorError };
