import { useEffect, useCallback } from 'react';
import shallow from 'zustand/shallow';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useGuarantorStore from '../state/Guarantor.store';
import APIProvider from '../utils/ApiProvider';
import { postVerifyCard, guarantorSubmit } from '../utils/endpoints';
import Header from '../components/header/Header';
import { useTranslation } from '@lendsqr/lingua-react';

const SubmitLoanGuarantee = () => {
  const navigate = useNavigate();

  const { translate } = useTranslation();
  const [setInitStatus, verifyToken] = useGuarantorStore(
    useCallback((s) => [s.setInitStatus, s.verifyToken], []),
    shallow
  );

  useEffect(() => {
    const token = localStorage.getItem('details_token');
    verifyToken(token as string);

    const handleVerifyCard = async () => {
      try {
        const payment_reference = localStorage.getItem('references');
        const res = (await APIProvider(postVerifyCard, 'post', {
          reference: payment_reference,
        })) as any;

        setInitStatus('success');
        const card_id = res.data.id ?? '';

        await APIProvider(guarantorSubmit, 'post', { card_id }).then(() => {
          navigate('/guarantor/success');
        });
      } catch (error: any) {
        setInitStatus('error');
        toast.error(
          error.message ?? translate('verification-web-app-add-card-error')
        );
      }
    };

    handleVerifyCard();
  }, []);

  return (
    <>
      <Header />
      <main className="validate">
        <div className="validate_illustration">
          <img
            className="illustration"
            src="/download-icons8.svg"
            alt="Illustration"
          />
          <div className="tagline">
            <span>{translate('verification-web-app-powered-by')}</span>
            <img src="/logo.svg" className="tagline-logo" alt="Logo" />
          </div>
        </div>
        <div className="validate_textarea">
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: '100vh' }}
          >
            <output className="spinner-border text-primary"></output>
          </div>
        </div>
      </main>
    </>
  );
};

export default SubmitLoanGuarantee;
