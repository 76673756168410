import React, { createContext, useEffect, useRef } from 'react';
import Home from './pages/Home';
import Layout from './components/layout/layout';
import Guarantor from './pages/Guarantor';
import OfficeEmail from './pages/OfficeEmail';
import SubmitLoanGuarantee from './pages/SubmitLoanGuarantee';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { LinguaProvider } from '@lendsqr/lingua-react';
import useGuarantorStore from './state/Guarantor.store';
import { Success } from './pages/Success';
import SmileID from './pages/SmileID';
import Service from './pages/Service';

export const ScrollContext =
  createContext<React.RefObject<HTMLDivElement> | null>(null);

function App() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [language, setLanguage, languageName, languageFlag] = useGuarantorStore(
    (state) => [
      state.language,
      state.setLanguage,
      state.languageName,
      state.languageFlag,
    ]
  );

  useEffect(() => {
    setLanguage(
      language as string,
      languageName as string,
      languageFlag as string
    );
  }, []);

  if (!language) return null;

  return (
    <LinguaProvider language={language}>
      <ScrollContext.Provider value={scrollableContainerRef}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="guarantor" element={<Guarantor />} />
              <Route path="office-email" element={<OfficeEmail />} />
              <Route
                path="borrower-email"
                element={<OfficeEmail is_borrower_email={true} />}
              />
              <Route
                path="guarantor/callback/"
                element={<SubmitLoanGuarantee />}
              />
              <Route path="guarantor/success" element={<Success />} />
              <Route path="/smileid" element={<SmileID />} />
              <Route path="/service" element={<Service />} />
            </Route>
          </Routes>
          <Toaster />
        </BrowserRouter>
      </ScrollContext.Provider>
    </LinguaProvider>
  );
}

export default App;
