import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

type DateType = string | Moment | Date;

interface DateInputProps {
  inputProps?: any;
  viewDate?: any;
  setValue: any;
  setError: any;
  clearErrors: any;
  initialDate?: DateType;
  minDate?: DateType;
  maxDate?: DateType;
  label?: string;
  // name: string
  error: string;
  groupClassName?: string;
  disabled?: boolean;
  id: string;
  value: string;
}

const DatePicker = ({
  label,
  id,
  minDate,
  maxDate,
  disabled,
  setError,
  setValue,
  clearErrors,
  value,
  initialDate,
}: DateInputProps) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [dayOpen, setDayOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);

  const months = moment.months(); // Get months using moment.js
  const [days, setDays] = useState<number[]>([]);

  // Determine the range of years
  const defaultMinYear = 1900;
  const defaultMaxYear = moment().year();

  const minYear = minDate ? moment(minDate).year() : defaultMinYear;
  const maxYear = maxDate ? moment(maxDate).year() : defaultMaxYear;

  // Generate year options
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => maxYear - i
  ); // Get the last 101 years using moment.js

  function getMonthFromDate(dateString: string) {
    console.log('initial date: ', dateString);

    const date = moment(dateString);
    const monthIndex = parseInt(`${date.month()}`, 10) + 1;
    const dayIndex = date.day();
    const yearIndex = date.year();
    const monthName = date.format('MMMM');
    const dayName = Number(date.format('D'));
    const yearName = date.format('YYYY');

    return { monthIndex, monthName, dayName, yearName, dayIndex, yearIndex };
  }

  useEffect(() => {
    if (initialDate !== undefined) {
      setValue(id, initialDate);

      // Setting initial dates
      const { monthIndex, yearIndex, dayName } = getMonthFromDate(
        initialDate as string
      );
      setSelectedDay(dayName);
      setSelectedYear(yearIndex);
      setSelectedMonth(monthIndex);
    }
  }, [initialDate]);

  useEffect(() => {
    const getDaysInMonth = (year: number, month: number) => {
      if (!year || !month) {
        return 30; // Return 30 if year or month is null or invalid
      }
      return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    };

    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  }, [selectedYear, selectedMonth]);

  const dateChange = (day: number, month: number, year: number) => {
    const date = moment({ year: year, month: month - 1, day: day });
    console.log('Selected date: ', date.format('YYYY/MM/DD'), day, month, year);

    if (!date.isValid() || year < 1) {
      setError(id, { type: 'manual', message: 'Please enter a valid date' });

      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      return;
    }

    setValue(id, date.format('YYYY/MM/DD'));

    const formattedDate = date.format('YYYY/MM/DD');
    const maximumDate = maxDate ? moment(maxDate).format('YYYY/MM/DD') : null;
    const minimumDate = minDate ? moment(minDate).format('YYYY/MM/DD') : null;

    if (maximumDate && formattedDate > maximumDate) {
      setError(id, {
        type: 'manual',
        message: `Date cannot be after ${maximumDate}`,
      });
      setError(id, {
        type: 'manual',
        message: `Date cannot be after ${maximumDate}`,
      });
    } else if (minimumDate && formattedDate < minimumDate) {
      setError(id, {
        type: 'manual',
        message: `Date cannot be before ${minimumDate}`,
      });
      setError(id, {
        type: 'manual',
        message: `Date cannot be before ${minimumDate}`,
      });
    } else {
      clearErrors(id);

      console.log('all clear', formattedDate);
    }
  };

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(Number(e.target.value));
    dateChange(Number(e.target.value), selectedMonth, selectedYear);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(e.target.value));
    dateChange(selectedDay, Number(e.target.value), selectedYear);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(e.target.value));
    dateChange(selectedDay, selectedMonth, Number(e.target.value));
  };

  return (
    <div className="form-group form-floating">
      <label className="text-sm mb-1" htmlFor={label?.split(' ').join('')}>
        {`${label} (${value && value?.length == 10 ? value : 'Enter date'})`}
      </label>
      <div id={id} className="date-selects">
        <div
          className="select-box form-control non-tel-inputs"
          onClick={() => setDayOpen(!dayOpen)}
        >
          <span className={`custom-arrow ${dayOpen ? 'date-open' : 'close'}`} />
          <select
            id="day"
            className="appearance-none"
            value={selectedDay}
            onChange={handleDayChange}
            onBlur={() => setDayOpen(false)}
            disabled={disabled}
            // className={`${error !== '' ? "date-select-error" : ""}`}
          >
            <span className="custom-arrow" />
            <option value="">Day</option>
            {days.map((day: number) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>

        <div
          className="select-box form-control non-tel-inputs"
          onClick={() => setMonthOpen(!monthOpen)}
        >
          <span
            className={`custom-arrow ${monthOpen ? 'date-open' : 'close'}`}
          />
          <select
            value={selectedMonth}
            className="appearance-none"
            onChange={handleMonthChange}
            disabled={disabled}
            onBlur={() => setMonthOpen(false)}
            // className={`${error !== '' ? "date-select-error" : ""}`}
          >
            <option value="">Month</option>
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <div
          className="select-box form-control non-tel-inputs"
          onClick={() => setYearOpen(!yearOpen)}
        >
          <span
            className={`custom-arrow ${yearOpen ? 'date-open' : 'close'}`}
          />
          <select
            value={selectedYear}
            className="appearance-none"
            onChange={handleYearChange}
            disabled={disabled}
            onBlur={() => setYearOpen(false)}
            // className={`${error !== '' ? "date-select-error" : ""}`}
          >
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
