import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import useServiceStore from '../state/Service.store';

interface AdditionalParams extends AxiosRequestConfig {
  Authorization?: string;
  authorization?: string;
  ContentType?: string;
  XLocale?: string;
}

const setupInterceptors = (
  axiosInstance: AxiosInstance
  // headers: AxiosRequestConfig['headers']
) => {
  // Add an interceptor to modify the request config before making the request

  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers = config.headers ?? {};

      const savedLocale =
        sessionStorage.getItem('locale') ?? navigator.language;
      const accessToken =
        localStorage.getItem('access_token') ??
        useServiceStore.getState().rent_report_form_data?.token;

      if (accessToken) {
        // headers?.Authorization = `Bearer ${accessToken}`;
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      // Add the 'x-locale' header if needed
      config.headers['x-locale'] = savedLocale;

      return config;
    },
    (error) => {
      // Handle request error here
      return Promise.reject(error);
    }
  );
};

const apiCall = async (
  url: string,
  httpMethod: string,
  body: unknown,
  additionalParams: AdditionalParams
) => {
  const headers: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_APP_X_API_KEY ?? '',
  };

  const baseApiCall = async () => {
    const axiosInstance: AxiosInstance = axios.create({
      headers,
    });

    // Set up interceptors
    setupInterceptors(axiosInstance);

    return axiosInstance;
  };

  if (additionalParams.ContentType) {
    headers['Content-Type'] = additionalParams.ContentType;
  }

  const axiosInstance = await baseApiCall();
  switch (httpMethod) {
    case 'post':
    case 'put':
    case 'patch':
      return axiosInstance[httpMethod](url, body, additionalParams);
    case 'get':
      return axiosInstance[httpMethod](url, { data: body });
    case 'delete':
      return axiosInstance[httpMethod](url, { data: '' });
    default:
      return null;
  }
};

const APIProvider = async (
  url: string,
  httpMethod: string,
  body = {},
  additionalParams = {}
) => {
  return new Promise((resolve, reject) => {
    apiCall(url, httpMethod, body, additionalParams)
      .then((response) => {
        if (response?.status && response?.status < 400) {
          if (response?.data.status >= 400) {
            reject(response?.data);
          } else {
            resolve(response?.data);
          }
        } else {
          reject(response?.data);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err);
        }
        if (err.isAxiosError) {
          reject(err.response?.data);
        }
        if (err.message) {
          console.log(err.message);
        }

        reject(err);
      });
  });
};

export default APIProvider;
