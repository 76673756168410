import { useCallback } from 'react';
import useServiceStore from '../../state/Service.store';

const ServiceError = () => {
  const [error_message] = useServiceStore(
    useCallback((s) => [s.error_message], [])
  );

  return (
    <div className="validate_success" id="approve-failed">
      <div className="icon">
        <img src="/failed.svg" alt="" />
      </div>
      <h5 className="validate_form-header">
        There&apos;s an error with this service request
      </h5>
      <p className="validate_form-sub">{error_message}</p>
    </div>
  );
};

export { ServiceError };
