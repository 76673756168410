import React, { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import useGuarantorStore from '../state/Guarantor.store';
import {
  AcceptLoan,
  DeclineLoan,
  GuarantorInit,
  GuarantorError,
  Success,
  GuarantorDocument,
} from '../components/guarantor';
import { useTranslation } from '@lendsqr/lingua-react';
import Header from '../components/header/Header';

const Guarantor = () => {
  const { translate } = useTranslation();
  const [searchParams] = useSearchParams();

  const [screen, verifyToken, loading] = useGuarantorStore(
    useCallback((s) => [s.screen, s.verifyToken, s.loading], [])
  );
  const token = searchParams.get('token') as string;

  const navigateScreen = () => {
    const navigator: Record<string, JSX.Element> = {
      default: <GuarantorInit />,
      accept: <AcceptLoan />,
      document: <GuarantorDocument />,
      decline: <DeclineLoan />,
      error: <GuarantorError />,
      success: <Success />,
    };

    return navigator[screen] ?? navigator.default;
  };

  // Scroll to the top whenever screen changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screen]);

  useEffect(() => {
    if (!token) {
      throw new Error(translate('verification-web-app-request-support-error'));
    }

    verifyToken(token);
  }, [token]);

  return (
    <div>
      <Header />
      <main className="validate">
        <div className="validate_illustration">
          <img
            className="illustration"
            src="/download-icons8.svg"
            alt="Illustration"
          />
          <div className="tagline">
            <span>{translate('verification-web-app-powered-by')}</span>
            <img src="/logo.svg" className="tagline-logo" alt="Logo" />
          </div>
        </div>
        <div className="validate_textarea">
          {loading ? (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <output className="spinner-border text-primary"></output>
            </div>
          ) : (
            // <Modal />
            navigateScreen()
          )}
        </div>
      </main>
    </div>
  );
};

export default Guarantor;
