import React from 'react';
import { classNames } from '../../utils';

interface ButtonProps {
  isLoading?: boolean;
  className: string;
  isDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  onClick?: () => void;
}

const Button = ({
  isLoading,
  className,
  isDisabled,
  children,
  type,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type ?? 'button'}
      disabled={isLoading ?? isDisabled}
      className={classNames(className, 'btn')}
      {...props}
    >
      {isLoading ? (
        <div
          style={{ maxHeight: '100%' }}
          className="spinner-border spinner-border-sm"
          role="output"
        ></div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
