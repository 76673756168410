import { useCallback } from 'react';
import useServiceStore from '../../state/Service.store';

const ServiceSuccess = () => {
  const [rent_report_form_data] = useServiceStore(
    useCallback((s) => [s.rent_report_form_data], [])
  );

  return (
    <div className="decline-request">
      <div className="validate_success" id="decline-success">
        <div className="icon">
          <img src="/success-icon.svg" alt="" />
        </div>
        <h3 className="validate_form-header">Verify Request</h3>
        <p className="validate_form-sub">
          You have successfully verified{' '}
          <span className="borrowerFirstName">{`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}'s `}</span>
          {`${rent_report_form_data?.service.attribute.name}`} Request
        </p>
      </div>
    </div>
  );
};

export { ServiceSuccess };
