/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import useGuarantorStore from '../../state/Guarantor.store';
import { formatCurrency, maskEmail } from '../../utils';
import Button from '../form/Button';
import { useTranslation } from '@lendsqr/lingua-react';
import { BorrowerLoanDetailsInterface } from '../../utils/interfaces';

const GuarantorInit = () => {
  const { translate } = useTranslation();
  const [setScreen, loanData, currency] = useGuarantorStore(
    useCallback(
      (s) => [
        s.setScreen,
        s.loanData as BorrowerLoanDetailsInterface,
        s.currency,
      ],
      []
    )
  );

  return (
    <div className="validate_textarea">
      <div className="accept-request" id="accept-page">
        <div className="validate_form" id="main">
          <h3 className="validate_form-header">
            {translate('verification-web-app-guarantee')}{' '}
            <span className="borrowerFirstName">{`${loanData.first_name}'s `}</span>
            {translate('verification-web-app-loan')}
          </h3>
          <p className="validate_form-sub">
            {translate('verification-web-app-guarantee-loan-description')}
            <span id="borrowerName" className="bold">
              {' '}
              {`${loanData.first_name} ${loanData.last_name}`}
            </span>{' '}
            {translate('verification-web-app-on-a')}{' '}
            <b>{formatCurrency(loanData.amount, currency as string)}</b>{' '}
            {translate('verification-web-app-loan-from')} {loanData.org_name}.
          </p>
          <p className="validate_form-sub">
            {translate('verification-web-app-guarantor-payback')}{' '}
            <span id="borrowerFirstName2" className="bold">
              {loanData.first_name}
            </span>{' '}
            {translate('verification-web-app-guarantor-payback-2')}
          </p>
          <div className="validate_form-body">
            <div className="info">
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">
                      {translate('verification-web-app-borrower-details')}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-full-name')}
                    </p>
                    <p className="info--value">{`${loanData.first_name} ${loanData.last_name}`}</p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-phone-number')}
                    </p>
                    <p className="info--value">
                      {loanData.phone_number?.replace(
                        loanData.phone_number?.substring(4, 7),
                        '***'
                      )}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-email-address')}
                    </p>
                    <p className="info--value">
                      {maskEmail(loanData.email || '')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">
                      {translate('verification-web-app-loan-details')}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-loan-amount')}
                    </p>
                    <p className="info--value">
                      {formatCurrency(loanData.amount, currency as string)}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-interest')}
                    </p>
                    <p className="info--value">
                      {formatCurrency(loanData.interest, currency as string)}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-tenor')}
                    </p>
                    <p className="info--value">{loanData.tenor}</p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-application-date')}
                    </p>
                    <p className="info--value">{loanData.date}</p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-start-date')}
                    </p>
                    <p className="info--value">{loanData.date}</p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-end-date')}
                    </p>
                    <p className="info--value">{loanData.due_date}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttonGroup">
            <Button
              onClick={() => setScreen('decline')}
              className="btn-dark"
              type="button"
            >
              {translate('verification-web-app-no-thanks')}
            </Button>
            <Button
              onClick={() => setScreen('accept')}
              type="submit"
              className="btn-primary"
            >
              {translate('verification-web-app-guarantee-loan', {
                borrower: `${loanData.first_name}`,
              })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GuarantorInit };
