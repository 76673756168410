import { toast } from 'react-hot-toast';
import create from 'zustand';
import APIProvider from '../utils/ApiProvider';
import { approveEmail, verifyBorrowerEmailUrl } from '../utils/endpoints';
import {
  EmailStoreInterface,
  OfficeEmailValidatePayload,
} from '../utils/interfaces';
import { persist } from 'zustand/middleware';

const defaultState = {
  loading: false,
  rightSubmitting: false,
  leftSubmitting: false,
  cancelled: false,
  successfull: false,
  emailSection: '',
  lenderWebApp: {},
};

const EmailStore = (set: any, get: any) => ({
  ...defaultState,
  setSection: () => {
    set({ emailSection: 'Office Email' });
  },
  cancelLoan: async () => {
    set({ leftSubmitting: true });
    try {
      await APIProvider('/false', 'delete');
      set({ leftSubmitting: false, cancelled: true });
    } catch (error: any) {
      set({ leftSubmitting: false });
      if (error.isAxiosError) {
        toast.error(error.response.data.message);
        return Promise.reject(error.response.data.message);
      }
      toast.error(error.message);
      return Promise.reject(error.message);
    }
  },
  submitEmail: async (token: string, payload: OfficeEmailValidatePayload) => {
    set({ rightSubmitting: true });

    try {
      await APIProvider(approveEmail(token), 'post', payload);
      set({ rightSubmitting: false, successfull: true });
    } catch (error: any) {
      set({ rightSubmitting: false });
      if (error.isAxiosError) {
        toast.error(error.response.data.message);
        return Promise.reject(error.response.data.message);
      }
      toast.error(error.message);
      return Promise.reject(error.message);
    }
  },

  verifyBorrowerEmail: async ({
    token,
    email,
  }: {
    token: string;
    email: string;
  }) => {
    set({ rightSubmitting: true });

    try {
      await APIProvider(verifyBorrowerEmailUrl(token), 'post', {
        email,
      });
      set({ rightSubmitting: false, successfull: true });
    } catch (error: any) {
      set({ rightSubmitting: false });
      if (error.isAxiosError) {
        toast.error(error.response.data.message);
        return Promise.reject(error.response.data.message);
      }
      toast.error(error.message);
      return Promise.reject(error.message);
    }
  },
});

const EmailStorage = {
  getItem: async (name: string) => {
    return sessionStorage.getItem(name);
  },
  setItem: async (name: string, value: string) => {
    const data = JSON.parse(value);
    sessionStorage.setItem(
      name,
      JSON.stringify({
        ...data,
        state: { ...data.state, loading: false },
      })
    );
  },
  removeItem: async (name: string) => {
    console.log(name);
  },
};

const useEmailStore = create<EmailStoreInterface>(
  persist(EmailStore, {
    name: 'email_data',
    getStorage: () => EmailStorage,
  })
);

export default useEmailStore;
