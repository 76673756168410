import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import './style.css';
import * as Sentry from '@sentry/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: '#ffffff',
      },
      '*': {
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
      },
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  components: {
    Button: {
      variants: {
        transparent: {
          bg: 'transparent',
        },
        green: {
          bg: '#39cdcc',
          color: '#fff',
          _hover: {
            _disabled: {
              bg: '#2ba8a8',
            },
            bg: '#2ba8a8',
          },
        },
        isFullWidth: true,
      },
    },
    Input: {
      variants: {
        bottomBorder: {
          field: {
            borderBottomColor: 'gray.800',
          },
        },
      },
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_KEY as string,
  environment: import.meta.env.NODE_ENV ?? ('development' as string),
  debug: import.meta.env.NODE_ENV === 'development',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
