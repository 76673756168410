import Button from '../form/Button';
import { useTranslation } from '@lendsqr/lingua-react';
import { useCallback } from 'react';
import useServiceStore from '../../state/Service.store';
import { convertSnakeToLabel } from '../../utils/formatter';

const ServiceBreakdown = () => {
  const { translate } = useTranslation();

  const [
    setScreen,
    attributes,
    submitRequest,
    rightSubmitting,
    additional_data,
    rent_report_form_data,
  ] = useServiceStore(
    useCallback(
      (s) => [
        s.setScreen,
        s.attributes,
        s.submitRequest,
        s.rightSubmitting,
        s.additional_data,
        s.rent_report_form_data,
      ],
      []
    )
  );

  const verifyRequest = () => {
    submitRequest();
  };

  return (
    <div className="validate_textarea">
      <div className="accept-request" id="accept-page">
        <div className="validate_form" id="main">
          <h3 className="validate_form-header">Request Breakdown</h3>
          <div className="validate_form-body">
            <div className="info">
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">Customer&apos;s Details</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-full-name')}
                    </p>
                    <p
                      className="info--value"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {' '}
                      {`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}`}{' '}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-phone-number')}
                    </p>
                    <p className="info--value">
                      {rent_report_form_data?.user.phone_number}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-email-address')}
                    </p>
                    <p className="info--value">
                      {rent_report_form_data?.user.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">
                      Requester&apos;s Submitted Data
                    </h3>
                  </div>
                </div>
                <div className="row">
                  {Object.entries(rent_report_form_data.data).map(
                    ([key, value], idx) => (
                      <div className="col-6 info-group" key={idx}>
                        <p className="info--title">
                          {convertSnakeToLabel(key)}
                        </p>
                        <p className="info--value">
                          {Array.isArray(value)
                            ? value.join(', ')
                            : String(value)}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">Verifier Details</h3>
                  </div>
                </div>
                <div className="row">
                  {attributes &&
                    Object.entries(
                      JSON.parse(attributes)[
                        additional_data?.meta?.name as string
                      ]
                    ).map(([key, value], idx) => (
                      <div className="col-6 info-group" key={idx}>
                        <p className="info--title">
                          {convertSnakeToLabel(key)}
                        </p>
                        <p className="info--value">
                          {Array.isArray(value)
                            ? value.join(', ')
                            : String(value)}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="buttonGroup">
            <Button
              onClick={() => setScreen('accept')}
              className="btn-dark"
              type="button"
            >
              {translate('verification-web-app-back')}
            </Button>
            <Button
              onClick={verifyRequest}
              isLoading={rightSubmitting}
              type="submit"
              className="btn-primary"
            >
              Verify request
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServiceBreakdown };
