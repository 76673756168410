import Button from '../form/Button';
import { useTranslation } from '@lendsqr/lingua-react';
import { useCallback, useContext } from 'react';
import useServiceStore from '../../state/Service.store';
import { convertSnakeToLabel } from '../../utils/formatter';
import { ScrollContext } from '../../App';

const ServiceDetails = () => {
  const { translate } = useTranslation();

  const scrollRefObject = useContext(ScrollContext);

  const [
    setScreen,
    rent_report_form_data,
    additional_data,
    declineRequest,
    leftSubmitting,
    rightSubmitting,
    submitRequest,
    scrollToTop,
  ] = useServiceStore(
    useCallback(
      (s) => [
        s.setScreen,
        s.rent_report_form_data,
        s.additional_data,
        s.declineRequest,
        s.leftSubmitting,
        s.rightSubmitting,
        s.submitRequest,
        s.scrollToTop,
      ],
      []
    )
  );

  const clfNavigate = () => {
    if (additional_data !== null) {
      setScreen('accept');
    } else {
      submitRequest();
    }

    scrollToTop(scrollRefObject);
  };

  return (
    <div className="validate_textarea">
      <div className="accept-request" id="accept-page">
        <div className="validate_form" id="main">
          <h3 className="validate_form-header">
            Verify{' '}
            <span className="borrowerFirstName">{`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}'s `}</span>
            {`${rent_report_form_data?.service.attribute.name}`} Request
          </h3>
          <p className="validate_form-sub">
            You have been asked to verify a{' '}
            <b>{`${rent_report_form_data?.service.attribute.name}`}</b> request
            by{' '}
            <b className="borrowerFirstName">{`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}`}</b>{' '}
            from <b>{`${rent_report_form_data?.organization.name}`}</b>.{' '}
            {/* //NOTE - Change org name to one from org details object */}
          </p>
          <p className="validate_form-sub">
            This is an important step for{' '}
            <b
              style={{ textTransform: 'capitalize' }}
            >{`${rent_report_form_data?.user.first_name}`}</b>
            . By verifying this request, you attest that the details provided by{' '}
            <b
              style={{ textTransform: 'capitalize' }}
            >{`${rent_report_form_data?.user.first_name}`}</b>
            . are true, correct, and complete.
          </p>
          <p className="validate_form-sub caution-box">
            Caution: Please note that attesting to false information can have
            serious consequences. It is important to only verify the request if
            you are certain about the accuracy of the information provided by{' '}
            <b
              style={{ textTransform: 'capitalize' }}
            >{`${rent_report_form_data?.user.first_name}`}</b>
            .
          </p>
          <div className="validate_form-body">
            <div className="info">
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">Requester&apos;s Details</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-full-name')}
                    </p>
                    <p
                      className="info--value"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {' '}
                      {`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}`}{' '}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-phone-number')}
                    </p>
                    <p className="info--value">
                      {rent_report_form_data?.user.phone_number}
                    </p>
                  </div>
                  <div className="col-6 info-group">
                    <p className="info--title">
                      {translate('verification-web-app-email-address')}
                    </p>
                    <p className="info--value">
                      {rent_report_form_data?.user.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="info-section">
                <div className="row info-nav align-items-center">
                  <div className="info-nav-title col">
                    <h3 className="info--heading">
                      Requester&apos;s Submitted Data
                    </h3>
                  </div>
                </div>
                <div className="row">
                  {rent_report_form_data?.data &&
                    Object.entries(rent_report_form_data?.data).map(
                      ([key, value], idx) => (
                        <div className="col-6 info-group" key={idx}>
                          <p className="info--title">
                            {convertSnakeToLabel(key)}
                          </p>
                          {(value as string).includes('https://') ? (
                            <a
                              href={`${value}`}
                              className="info--value"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: '#39cdcc', marginTop: '-3px' }}
                            >
                              Download {convertSnakeToLabel(key)}
                            </a>
                          ) : (
                            <p className="info--value">
                              {Array.isArray(value)
                                ? value.join(', ')
                                : String(value)}
                            </p>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="buttonGroup">
            <Button
              onClick={() => declineRequest('rejected')}
              isLoading={leftSubmitting}
              className="btn-dark"
              type="button"
            >
              {translate('verification-web-app-no-thanks')}
            </Button>
            <Button
              onClick={clfNavigate}
              type="submit"
              className="btn-primary"
              isLoading={rightSubmitting}
            >
              {additional_data !== null
                ? translate('verification-web-app-continue')
                : 'verify request'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServiceDetails };
