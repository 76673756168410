import React, { useCallback, useState } from 'react';
import './CardBtn.css';
import Modal from '../Modal/Modal';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import useGuarantorStore from '../../state/Guarantor.store';
import { useTranslation } from '@lendsqr/lingua-react';
import toast from 'react-hot-toast';

export type FileObject = {
  path?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  size: number;
  type?: string;
  webkitRelativePath?: string;
};

const getClassNameAndImage = (status: string) =>
  status
    ? [
        `badge rounded-pill__${status.toLowerCase()}`,
        `/images/${status.toLowerCase()}.png`,
      ]
    : [];

export default function CardBtn({
  status = '',
  fileName,
  id,
}: Readonly<{ status: string; fileName: string; id: number }>) {
  const { translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    if (['pending'].includes(status?.toLowerCase())) return;
    setShowModal((s) => !s);
  }, [status]);
  const [uploadDocument] = useGuarantorStore(
    useCallback((s) => [s.uploadDocument], [])
  );

  const [className, imageUrl] = getClassNameAndImage(
    status === 'Approved' ? 'verified' : status
  );

  const uploadFile = useCallback(
    async (file: Blob, cb: () => void) => {
      await uploadDocument(file, id, toggleModal);
      typeof cb === 'function' && cb();
    },
    [id, toggleModal]
  );

  return (
    <>
      <Modal
        // key={showModal}
        show={showModal}
        close={() => setShowModal(false)}
        description={fileName}
      >
        <div className="modal-body">
          <div className="modal-form">
            <Uploader onFileSelect={uploadFile} />
            <div className="tips">
              <p>{translate('verification-web-app-file-type-tooltip')}</p>
            </div>
          </div>
        </div>
      </Modal>
      <button onClick={toggleModal} className="card-btn">
        <span>{fileName}</span>
        {status && (
          <span className={className}>
            <img src={imageUrl} alt="" width="10px" />
            {status?.toUpperCase()}
          </span>
        )}
      </button>
    </>
  );
}

export function Uploader({
  onFileSelect,
}: Readonly<{ onFileSelect: Function }>) {
  const { translate } = useTranslation();
  const [file, setFile] = useState<FileObject | null>(null);

  const onDrop = useCallback(
    (files: FileObject[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        toast.error('File Format Not suppoorted, upload JPG or PDF');
        return;
      } else if (files[0].size / 1000000 > 5) {
        toast.error('Please upload a file less than 5MB');
        return;
      }
      if (files.length) {
        setFile(files[0]);
        onFileSelect(files[0], () => setFile(null));
      }
    },
    [onFileSelect]
  );
  const acceptedFileTypes: Accept = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  };

  const [status] = useGuarantorStore(useCallback((s) => [s.status], []));

  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/jpeg, image/png, application/pdf',
    onDrop,
    accept: acceptedFileTypes,
    maxSize: 4000000,
    maxFiles: 1,
  });

  let statusElement = null;

  if (status === 'loading') {
    statusElement = (
      <output className="spinner-border-sm text-primary"></output>
    );
  } else if (status === 'success') {
    statusElement = (
      <svg width="24" height="24">
        <use xlinkHref="/icon-sprite.svg#done" />
      </svg>
    );
  }

  return (
    <div className="form-group upload-btn btn d-flex " {...getRootProps()}>
      {!file ? (
        <>
          <input {...getInputProps()} />
          <div
            className="filelabel d-flex align-items-center justify-content-center flex-column"
            // htmlFor="uploadDocument"
          >
            <div className="upload-text">
              <div className="top">
                <h3>{translate('verification-web-app-drag-drop')}</h3>
                <p>
                  {translate('verification-web-app-or')}{' '}
                  <span className="text-primary">
                    <u>{translate('verification-web-app-browse')}</u>
                  </span>{' '}
                  {translate('verification-web-app-to-choose')}
                </p>
              </div>
              <div className="down">
                <p>{`(${translate(
                  'verification-web-app-file-size-tooltip'
                )})`}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <label className="filelabel successUpload">
          {status === 'success' && (
            <div className="btn-wrapper">
              <button className="btn btn-link text-danger">
                {translate('verification-web-app-remove')}
              </button>
            </div>
          )}
          <div className="file">
            <div className="file--wrapper">
              <div className="file-status">{statusElement}</div>
              {/* <div className="file-icon">
                <svg width="32" height="32">
                  <use xlinkHref="/icon-sprite.svg#file" />
                </svg>
              </div> */}
              <div>
                <p className="name">{file.name}</p>
                <p className="size">{(file.size / 1000000).toFixed(2)}MB</p>
              </div>
            </div>
          </div>
        </label>
      )}
    </div>
  );
}
