import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useGuarantorStore from '../../state/Guarantor.store';
import { Input } from '../form';
import Button from '../form/Button';
import { useTranslation } from '@lendsqr/lingua-react';
import {
  BorrowerLoanDetailsInterface,
  ProcessGuarantorInterface,
} from '../../utils/interfaces';
import moment from 'moment';
import { Box } from '@chakra-ui/react';
import DatePicker from '../base/DatePicker';

const AcceptLoan = () => {
  const { translate } = useTranslation();

  const relationships = [
    `${translate('verification-web-app-parent') || 'Parent'}`,
    `${translate('verification-web-app-brother') || 'Brother'}`,
    `${translate('verification-web-app-sister') || 'Sister'}`,
    `${translate('verification-web-app-friend') || 'Friend'}`,
    `${translate('verification-web-app-colleague') || 'Colleague'}`,
    `${translate('verification-web-app-neighbour') || 'Neighbour'}`,
    `${translate('verification-web-app-wife') || 'Wife'}`,
    `${translate('verification-web-app-husband') || 'Husband'}`,
    `${translate('verification-web-app-child') || 'Child'}`,
    `${translate('verification-web-app-others') || 'Others'}`,
  ];

  const [
    submitting,
    processGuarantor,
    loanData,
    setGuarantorData,
    setScreen,
    guarantor_details,
  ] = useGuarantorStore(
    useCallback(
      (s) => [
        s.submitting,
        s.processGuarantor,
        s.loanData as BorrowerLoanDetailsInterface,
        s.setGuarantorData,
        s.setScreen,
        s.guarantor_details,
      ],
      []
    )
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<ProcessGuarantorInterface>({
    mode: 'onChange',
    defaultValues: {
      ...guarantor_details,
    },
  });

  // Watch the changes in the "myField" form field
  const watchedDOB = watch('dob');

  const onSubmit = async (data: ProcessGuarantorInterface) => {
    console.log(data);

    const guarantorData = {
      address: data.address,
      email: data.email,
    };

    setGuarantorData({
      address: data.address,
      email: data.email,
    });

    localStorage.setItem('guarantorData', JSON.stringify(guarantorData));
    processGuarantor(data);
  };

  const pastDate = moment().subtract(18, 'years');

  // useEffect(() => {
  //   setValue('dob', pastDate.format('YYYY/MM/DD'))
  // }, [])

  return (
    <div className="accept-request" id="accept-page">
      <div className="validate_form " id="approve_form">
        <h3 className="validate_form-header">
          {' '}
          {translate('verification-web-app-guarantor-details')}
        </h3>
        <p className="validate_form-sub">
          {translate('verification-web-app-accept-loan')}{' '}
          <span>{`“${translate('verification-web-app-submit')}”`}</span>,{' '}
          {translate('verification-web-app-verify')}{' '}
          {loanData?.privacy_policy ? (
            <a
              href={loanData?.privacy_policy || ''}
              style={{ color: ' #213f7d' }}
              target="_blank"
              rel="noreferrer"
            >
              <b>{translate('verification-web-app-terms-conditions')}</b>
            </a>
          ) : (
            translate('verification-web-app-terms-conditions')
          )}{' '}
          {`${translate('verification-web-app-from')} ${loanData?.org_name}`}
        </p>

        <div className="validate_form-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="header">
              {translate('verification-web-app-form-prompt')}
            </p>
            <Input
              name="bvn"
              errors={errors}
              {...{ maxLength: 11 }}
              type="tel"
              label={translate('verification-web-app-bvn-label')}
              placeholder="2222222222"
              register={register}
              validations={{
                required: translate('verification-web-app-bvn-error'),
                setValueAs: (v: number) => String(v),
              }}
            />
            <div className="mb-3">
              <Controller
                name="dob"
                control={control}
                rules={{
                  required: translate('verification-web-app-dob-error'),
                }}
                render={() => (
                  <Box position={'relative'} width={'100%'}>
                    <DatePicker
                      id="dob"
                      error={errors.dob?.message as string}
                      value={watchedDOB}
                      maxDate={pastDate}
                      // initialDate={watchedDOB}
                      label={translate('invitation-web-app-dob-header')}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </Box>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="dob"
                render={({ message }) => (
                  <small className="error-message text-danger">{message}</small>
                )}
              />
            </div>
            <Input
              {...{ maxLength: 5 }}
              errors={errors}
              type="tel"
              label={translate('verification-web-app-bvn-input-instruction')}
              placeholder="11111"
              name="phone"
              register={register}
              validations={{
                required: translate(
                  'verification-web-app-bvn-input-instruction-error'
                ),
                setValueAs: (v: number) => String(v),
              }}
            />
            <Input
              errors={errors}
              placeholder={translate('verification-web-app-email-placeholder')}
              label={translate('verification-web-app-email-label')}
              name="email"
              register={register}
              // {...register('email', {
              //   required: translate('verification-web-app-email-error'),
              // })}
              validations={{
                required: translate('verification-web-app-email-error'),
              }}
            />
            <div className="form-group form-floating">
              <label className="text-sm mb-1" htmlFor="relationship">
                {translate('verification-web-app-relationship')}
              </label>
              <select
                defaultValue=""
                className="form-control non-tel-inputs"
                {...register('relationship', {
                  required: translate(
                    'verification-web-app-relationship-error'
                  ),
                })}
              >
                <option disabled value="">
                  {translate('verification-web-app-relationship-placeholder')}
                </option>
                {relationships.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <ErrorMessage
                errors={errors}
                name="relationship"
                render={({ message }) => (
                  <small className="error-message text-danger">{message}</small>
                )}
              />
            </div>
            <Input
              errors={errors}
              placeholder={translate(
                'verification-web-app-address-placeholder'
              )}
              label={translate('verification-web-app-address-label')}
              name="address"
              register={register}
              validations={{
                required: translate('verification-web-app-address-error'),
              }}
            />
            <div className="buttonGroup">
              <Button
                onClick={() => setScreen('default')}
                className="btn-dark"
                type="button"
              >
                {translate('verification-web-app-back')}
              </Button>
              <Button
                isLoading={submitting}
                type="submit"
                isDisabled={Object.keys(errors).length > 0}
                className="btn-primary "
              >
                {translate('verification-web-app-guarantee-loan', {
                  borrower: `${loanData.first_name}`,
                })}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { AcceptLoan };
