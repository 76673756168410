import { useState, useEffect, useContext } from 'react';
import { Box, VStack, Heading, Text, ButtonGroup } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Section from './Section';
import Action from './RenderFormAction';
import {
  ActionInterface,
  FieldInterface,
  PageInterface,
  SectionInterface,
  FormDataInterface,
  AdditionalReportDataInterface,
} from '../../../utils/interfaces';
import { ScrollContext } from '../../../App';
import { isObjectNotEmpty } from '../../../utils/formatter';
import useServiceStore from '../../../state/Service.store';

interface CLFCurrentPageProps {
  loanProduct: any;
  page: PageInterface | any;
  initialValues: any;
  setInitialValues: React.Dispatch<React.SetStateAction<any>>;
  dynamicInitials: (loanProduct: AdditionalReportDataInterface) => any;
}

const CLFCurrentPage = ({
  loanProduct,
  page,
  dynamicInitials,
  initialValues,
  setInitialValues,
}: CLFCurrentPageProps) => {
  const scrollRefObject = useContext(ScrollContext);

  const [
    setRentReportFormData,
    scrollToTop,
    additional_data,
    clf_page,
    setCLFPage,
    attributes,
    setCLFAttribute,
    setScreen,
  ] = useServiceStore((state) => [
    state.setRentReportFormData,
    state.scrollToTop,
    state.additional_data,
    state.clf_page,
    state.setCLFPage,
    state.attributes,
    state.setCLFAttribute,
    state.setScreen,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
    getFieldState,
  } = useForm<FormDataInterface>({
    defaultValues: {
      ...JSON.parse(attributes)[additional_data?.meta?.name as string],
    },
  });

  const filteredFormData: Record<string, string> = Object.keys(
    initialValues
  ).reduce((acc: Record<string, string>, key: string) => {
    if (
      page.sections.some((section: SectionInterface) =>
        section.fields.some(
          (field: FieldInterface) =>
            field.id === key &&
            field.validation.required === true &&
            field.type !== 'url'
        )
      )
    ) {
      acc[key] = initialValues[key];
    }

    return acc;
  }, {});

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !Object.keys(filteredFormData).every((key) => {
      const valueInGetValues = getValues()[key];
      return valueInGetValues !== undefined;
    })
  );

  const onSubmit = async (data: FormDataInterface) => {
    try {
      const dataKey = additional_data?.meta?.name;

      if (clf_page < loanProduct?.pages.length - 1) {
        const additionalData = JSON.stringify({
          [dataKey as string]: data,
        });
        setCLFAttribute(additionalData);

        setCLFPage(clf_page + 1);
      } else {
        const additionalData = JSON.stringify({
          [dataKey as string]: data,
        });

        setRentReportFormData({ attributes: additionalData });
        setCLFAttribute(additionalData);
        setScreen('breakdown');
      }

      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loanProduct) {
      setInitialValues(dynamicInitials(loanProduct));
    }
  }, [clf_page]);

  useEffect(() => {
    const values = getValues();

    const isAnyValueUndefined = !Object.keys(filteredFormData).every((key) => {
      const valueInGetValues = values[key];
      return valueInGetValues !== undefined;
    });

    const isAnyErrorNotEmpty = isObjectNotEmpty(errors);

    const result = isAnyValueUndefined || isAnyErrorNotEmpty;

    setIsButtonDisabled(result);
  }, [watch()]);

  const handlePreviousStep = () => {
    if (clf_page <= 0) {
      setScreen('default');
    } else {
      console.log(errors);
      setCLFPage(clf_page - 1);
    }
    scrollToTop(scrollRefObject);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
    >
      <VStack
        display="block"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        p="0"
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1rem' }}
          pt={'1rem'}
          fontSize={'1.5rem'}
          color={'#213f7d'}
          fontFamily={'Work Sans, sans-serif !important'}
          width="100%"
        >
          {page.title}
        </Heading>
        <Text fontSize="xs" mb={6}>
          {page.description}
        </Text>

        <Box w={'100%'} marginX={'auto'} padding={'1.875rem 0'}>
          {page.sections?.map((section: SectionInterface, idx: number) => (
            <div key={section.name}>
              <Text
                fontSize="lg"
                fontWeight="bold"
                color={'#213f7d'}
                mb="4px"
                mx="0"
              >
                {section.name}
              </Text>
              <Text fontSize="xs">{section.description}</Text>
              <Section
                key={idx}
                section={section}
                setValue={setValue}
                getValue={getValues}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                control={control}
                errors={errors}
                getFieldState={getFieldState}
              />
            </div>
          ))}
        </Box>
      </VStack>
      <Box
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="100%"
          variant="outline"
          spacing="7"
          gap={'2rem'}
          // flexDir={{ base: 'column' }}
        >
          {page.actions
            .map((action: ActionInterface) => (
              <Action
                key={action.type}
                action={action}
                onPrevious={handlePreviousStep}
                isDisabled={isButtonDisabled}
              />
            ))
            .reverse()}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default CLFCurrentPage;
