import { FC } from 'react';
import RenderForm from './RenderForm';
import { SectionInterface, FormDataInterface } from '../../../utils/interfaces';
import { Control, DeepMap, FieldError } from 'react-hook-form';

interface SectionProps {
  section: SectionInterface;
  register?: any;
  setValue?: any;
  getValue?: any;
  setError?: any;
  clearErrors?: any;
  control: Control<FormDataInterface>;
  errors: DeepMap<FormDataInterface, FieldError>;
  getFieldState?: any;
}

const Section: FC<SectionProps> = ({
  section,
  register,
  control,
  errors,
  setValue,
  getValue,
  setError,
  clearErrors,
  getFieldState,
}) => {
  return (
    <div style={{ marginTop: '1rem' }}>
      {section?.fields?.map((field, idx) => (
        <RenderForm
          key={idx}
          field={field}
          register={register}
          setValue={setValue}
          getValue={getValue}
          setError={setError}
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          getFieldState={getFieldState}
          zIndex={`${section?.fields?.length - idx}`}
        />
      ))}
    </div>
  );
};

export default Section;
