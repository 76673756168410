/* eslint-disable */
const apiUrl = import.meta.env.VITE_APP_API_URL;
export const baseURL = `${apiUrl}/v1`;
export const baseURL2 = `${apiUrl}/v2`;
export const utilityBaseUrl = `${import.meta.env.VITE_APP_UTILITY_URL}/v1`;
export const utilityBaseUrl2 = `${import.meta.env.VITE_APP_UTILITY_URL}/v2`;
export const walletBaseUrl = `${apiUrl}/v1`;
export const artis_url = `${import.meta.env.VITE_WEB_CONFIG_URL}/site`;

export const verifyRentReportToken = (token: string) =>
  baseURL + `/webhooks/service-request/${token}`;

export const verifyGuarantorToken = (token: string) =>
  baseURL2 + `/guarantor/${token}`;
export const processGuarantor = (token: string) =>
  baseURL2 + `/guarantor/?token=${token}`;
export const addDocument = baseURL2 + `/guarantor/document`;

export const postUploadPhoto = utilityBaseUrl2 + `/upload/photo`;
export const postUploadFile = utilityBaseUrl2 + `/upload/file`;
export const getDocumentTypes = baseURL2 + `/settings/document/types`;

export const getAllBanks = utilityBaseUrl + '/codes/fetch/bank';
export const postVerifyBankAccount = utilityBaseUrl + '/verify/bank';
export const postInitCard = (callbackUrl: string) =>
  `${baseURL2}/guarantor/card/init?channel=App&callback_url=${callbackUrl}`;
export const postVerifyCard = baseURL2 + '/guarantor/card'; //Called after card added. payload is reference from init card response

export const createMandate = baseURL2 + '/guarantor/mandates';
export const verifyMandateOtp = baseURL2 + '/guarantor/mandates/validate-otp';

export const guarantorSubmit = baseURL2 + '/guarantor/submit'; //called to submit card for guarantor thingy. payload is card id

export const declineLoanGuarantee = (token: string) =>
  baseURL + `/webhooks/guarantor/response?token=${token}`;

export const approveEmail = (token: string) =>
  baseURL + `/webhooks/validate-email?token=${token}`;

export const verifyBorrowerEmailUrl = (token: string) =>
  baseURL + `/webhooks/validate-user-email?token=${token}`;

export const getWebApp = (org: string) => baseURL + `/site/${org}`;

export const getPreferences = baseURL2 + `/invites/preferences?channel=web`;
export const themeDetails = (domain: string) => artis_url + `/${domain}`;

export const postCLFUploadPhoto = utilityBaseUrl + `/upload/photo`;
export const postCLFUploadFile = utilityBaseUrl + `/upload/file`;
