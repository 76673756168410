import React from 'react';
import { useTranslation } from '@lendsqr/lingua-react';

function Home() {
  const { translate } = useTranslation();
  return (
    <>
      <p> {translate('verification-web-app-home')} </p>
    </>
  );
}

export default Home;
