import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { ActionInterface } from '../../../utils/interfaces';

interface RenderFormActionProps {
  action: ActionInterface;
  onContinue?: () => void;
  isDisabled?: boolean;
  onPrevious: () => void;
}

const ACTION_TYPES = {
  continue: 'continue',
  cancel: 'cancel',
  submit: 'submit',
};

const RenderFormAction: FC<RenderFormActionProps> = ({
  action,
  onContinue,
  isDisabled,
  onPrevious,
}) => {
  return (
    <>
      {(() => {
        switch (action.type?.toLowerCase()) {
          case ACTION_TYPES.continue:
          case ACTION_TYPES.submit:
            return (
              <Button
                onClick={onContinue}
                size="lg"
                variant="green"
                type={'submit'}
                isDisabled={isDisabled}
                fontSize={{ base: '14px' }}
                mt="1rem"
                w={'100%'}
                border={'0'}
                borderRadius={'8px'}
                fontWeight={'600'}
                height={'48px'}
                cursor={'pointer'}
                textTransform={'uppercase'}
                color={'#fff'}
                bg={'#39cdcc'}
                borderColor={'#39cdcc'}
                _hover={{
                  bg: '#2db3b2',
                  borderColor: '#2db3b2',
                }}
                _disabled={{
                  opacity: 0.5,
                  _hover: {
                    bg: '#39cdcc',
                    borderColor: '#39cdcc',
                  },
                }}
              >
                {action.label}
              </Button>
            );
          case ACTION_TYPES.cancel:
            return (
              <Button
                onClick={onPrevious}
                size="lg"
                color="#545f7d"
                bg={'#f1f4f4'}
                border={'0'}
                borderRadius={'8px'}
                fontWeight={'600'}
                height={'48px'}
                textTransform={'uppercase'}
                fontSize={{ base: '14px' }}
                mt="1rem"
                w={'100%'}
                _hover={{ bg: '#dae2e2' }}
              >
                Back
              </Button>
            );
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default RenderFormAction;
