// localeConfig.ts

import { registerLocale } from 'react-datepicker';
import { enUS, fr } from 'date-fns/locale';

// Define custom Swahili locale
// const swahiliLocale = {
//   name: 'sw',
//   localize: {
//     day: (n: number) => {
//       return [
//         'Jumapili',
//         'Jumatatu',
//         'Jumanne',
//         'Jumatano',
//         'Alhamisi',
//         'Ijumaa',
//         'Jumamosi',
//       ][n];
//     },
//     month: (n: number) => {
//       return [
//         'Januari',
//         'Februari',
//         'Machi',
//         'Aprili',
//         'Mei',
//         'Juni',
//         'Julai',
//         'Agosti',
//         'Septemba',
//         'Oktoba',
//         'Novemba',
//         'Desemba',
//       ][n];
//     },
//   },

//   today: 'Leo',
//   clear: 'Futa',
//   formatLong: {
//     date: () => {
//       return {
//         weekday: 'long',
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric',
//       };
//     },
//     time: () => {
//       return {
//         hour: 'numeric',
//         minute: 'numeric',
//       };
//     },
//     dateTime: () => {
//       return {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric',
//         hour: 'numeric',
//         minute: 'numeric',
//       };
//     },
//   },
// };

// Register the Swahili locale
// registerLocale('sw', swahiliLocale);

// Register locales
registerLocale('en-US', enUS);
registerLocale('fr', fr);

export const SupportedLocales = {
  enUS: 'en-US',
  fr: 'fr',
  sw: 'en-US',
  rw: 'en-US',
};
