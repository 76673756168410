/* eslint-disable no-unused-vars */
import { ErrorMessage } from '@hookform/error-message';

interface InputProps {
  name: string;
  errors: any;
  type?: string;
  label?: string;
  placeholder: string;
  validations?: any;
  register?: any;
  onChange?: () => void;
}

const Input = ({
  name,
  errors,
  type = 'text',
  label,
  placeholder,
  validations,
  register,
  ...rest
}: InputProps) => {
  return (
    <div className="form-group form-floating">
      <label className="text-sm mb-1" htmlFor={label?.split(' ').join('')}>
        {label}
      </label>
      <input
        type={type}
        className="form-control non-tel-inputs"
        id={name}
        placeholder={placeholder}
        {...(register ? register(name, validations) : {})}
        {...rest}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <small className="error-message text-danger">{message}</small>
        )}
      />
    </div>
  );
};

export { Input };
