import {
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Box,
  Spinner,
  HStack,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from '@lendsqr/lingua-react';
import React, { ReactNode } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegisterReturn,
} from 'react-hook-form';

interface OptionsInterface {
  label?: string;
  value?: string;
  id?: string;
}

interface CustomSelectProps {
  id?: string;
  label: string;
  isInvalid: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined;
  errorMessage?: ReactNode;
  formHook?: UseFormRegisterReturn<string>;
  options: OptionsInterface[] | null;
  placeholder?: string;
  value?: string;
  loader?: boolean;
  bankAccounts?: boolean;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CustomSelect = ({
  id,
  label,
  isInvalid,
  errorMessage,
  formHook,
  options,
  loader,
  ...rest
}: CustomSelectProps) => {
  const { translate } = useTranslation();

  return (
    <FormControl isInvalid={!!isInvalid} id={id}>
      <HStack gap={'0.2rem'} alignItems={'flex-start'}>
        <FormLabel
          color={'rgba(33, 37, 41, 0.7)'}
          fontSize="0.885rem"
          mb="4px"
          mx="0"
          fontWeight={'light'}
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {label}
        </FormLabel>
        {loader && <Spinner color="#39cdcc" size="xs" ml="5px" />}
      </HStack>
      <Select
        h="50px"
        w={'100%'}
        borderRadius={'8px'}
        //  padding={'0.375rem 1.25rem'}
        variant={{ base: 'outline' }}
        border="1px solid rgba(84, 95, 125, .15)"
        sx={{
          '&:disabled': {
            border: '1px solid #b0b8c8',
            bg: '#ECEEF0',
            opacity: '1',
          },
        }}
        _placeholder={{
          fontSize: '0.7rem',
          color: 'hsl(0, 0%, 50%)',
          opacity: '0.5',
        }}
        placeholder={`${translate('invitation-web-app-select') || 'Select'}`}
        {...formHook}
        {...rest}
      >
        {options?.map((option) => (
          <option key={option.label} value={option?.value}>
            {id === 'accounts' ? option?.label : option?.value}
          </option>
        ))}
      </Select>
      <Box pt="0.4rem" h={`${errorMessage ? '2rem' : '15px'}`}>
        <FormErrorMessage mt="0" fontSize="0.65rem">
          {errorMessage}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
};

const ControlSelect = ({
  id,
  label,
  options,
  errorMessage,
  isInvalid,
  formHook,
  ...rest
}: CustomSelectProps) => {
  const { translate } = useTranslation();
  return (
    <FormControl px="1" isInvalid={!!isInvalid} id={id}>
      <FormLabel
        color={'rgba(33, 37, 41, 0.7)'}
        fontSize="0.885rem"
        mb="4px"
        fontWeight={'light'}
        sx={{
          '@media screen and (min-width: 768px) and (orientation: portrait)': {
            fontWeight: 'bold',
          },
          '@media screen and (min-width: 1024px)': {
            fontWeight: 'bold',
          },
        }}
      >
        {label}
      </FormLabel>{' '}
      <Select
        h="50px"
        w={'100%'}
        borderRadius={'8px'}
        //  padding={'0.375rem 1.25rem'}
        variant={{ base: 'outline' }}
        border="1px solid rgba(84, 95, 125, .15)"
        sx={{
          '&:disabled': {
            border: '1px solid #b0b8c8',
            bg: '#ECEEF0',
            opacity: '1',
          },
        }}
        _placeholder={{
          fontSize: '0.7rem',
          color: 'hsl(0, 0%, 50%)',
          opacity: '0.5',
        }}
        placeholder={`${translate('invitation-web-app-select')}`}
        {...formHook}
        {...rest}
      >
        {options?.map((item) => (
          <option key={item.label} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      <Box pt="0.4rem" h={`${errorMessage ? '2rem' : '15px'}`}>
        <FormErrorMessage mt="0" fontSize="0.65rem">
          {errorMessage}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
};

export { CustomSelect, ControlSelect };
