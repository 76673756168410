import { useState } from 'react';
import CLFCurrentPage from './configurable_forms/components/CLFCurrentPage';
import {
  AdditionalReportDataInterface,
  FieldInterface,
  FormDataInterface,
  PageInterface,
  SectionInterface,
} from '../utils/interfaces';
import useServiceStore from '../state/Service.store';

const ConfigurableForms = () => {
  const [additional_data, clf_page] = useServiceStore((state) => [
    state.additional_data,
    state.clf_page,
  ]);

  const [initialValues, setInitialValues] = useState<FormDataInterface>({});
  const loanProduct = additional_data;

  const dynamicInitials = (loanProduct: AdditionalReportDataInterface) => {
    const initialVals: FormDataInterface = {};

    loanProduct?.pages?.forEach((page: PageInterface) => {
      page?.sections?.forEach((section: SectionInterface) => {
        section?.fields?.forEach((field: FieldInterface) => {
          initialVals[field.id] = '';
        });
      });
    });

    return initialVals;
  };

  return (
    <div className="validate_textarea">
      <div className="accept-request" id="accept-page">
        <div className="validate_form" id="main">
          {initialValues && (
            <>
              {loanProduct &&
                ((page) => (
                  <CLFCurrentPage
                    page={page}
                    loanProduct={loanProduct}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    dynamicInitials={dynamicInitials}
                  />
                ))(loanProduct.pages[clf_page])}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurableForms;
