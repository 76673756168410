import { useTranslation } from '@lendsqr/lingua-react';
import Header from '../components/header/Header';
import {
  DeclineRequest,
  ServiceDetails,
  ServiceBreakdown,
  ServiceSuccess,
  ServiceError,
} from '../components/service';
import useServiceStore from '../state/Service.store';
import { useCallback, useEffect } from 'react';
import ConfigurableForms from '../components/ConfigurableForms';
import { useSearchParams } from 'react-router-dom';

const ServiceReport = () => {
  const { translate } = useTranslation();
  const [searchParams] = useSearchParams();

  const [screen, loading, getReportDetails, clf_page] = useServiceStore(
    useCallback(
      (s) => [s.screen, s.loading, s.getReportDetails, s.clf_page],
      []
    )
  );

  const token = searchParams.get('token') as string;

  const navigateScreen = () => {
    const navigator: Record<string, JSX.Element> = {
      default: <ServiceDetails />,
      accept: <ConfigurableForms />,
      breakdown: <ServiceBreakdown />,
      decline: <DeclineRequest />,
      success: <ServiceSuccess />,
      error: <ServiceError />,
    };

    return navigator[screen] ?? navigator.default;
  };

  // Scroll to the top whenever screen changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screen, clf_page]);

  useEffect(() => {
    if (!token) {
      throw new Error(translate('verification-web-app-request-support-error'));
    }

    getReportDetails(token);
  }, []);

  return (
    <div>
      <Header />
      <main className="validate">
        <div className="validate_illustration">
          <img
            className="illustration"
            src="/download-icons8.svg"
            alt="Illustration"
          />
          <div className="tagline">
            <span>{translate('verification-web-app-powered-by')}</span>
            <img src="/logo.svg" className="tagline-logo" alt="Logo" />
          </div>
        </div>
        <div className="validate_textarea">
          {loading ? (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <output className="spinner-border text-primary"></output>
            </div>
          ) : (
            navigateScreen()
          )}
        </div>
      </main>
    </div>
  );
};

export default ServiceReport;
