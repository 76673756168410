const qs = new URLSearchParams(window.location.search);
const languageCode = qs.get('language');
const locale = qs.get('locale') ?? navigator.language;
const currency = qs.get('currency') ?? 'NGN';
console.log(languageCode, locale, currency);

export * from './classNames';

export function maskEmail(email: string) {
  const atIndex = email.indexOf('@');
  let name = email.substring(0, atIndex);
  const domain = email.substring(atIndex + 1);

  if (name.length === 1) {
    name = name + '***';
  } else if (name.length === 2 || name.length === 3 || name.length === 4) {
    const maskedLetters = name.substring(1);
    name = name.replace(maskedLetters, '***');
  } else if (name.length === 5 || name.length === 6) {
    const maskedLetters = name.substring(2);
    name = name.replace(maskedLetters, '***');
  } else {
    const maskedLetters = name.substring(3);
    name = name.replace(maskedLetters, '***');
  }

  return `${name}${domain}`;
}

export const formatCurrency = (amount: string, setCurrency: string) => {
  try {
    // Extract numeric part from string if input is in format "$1000"
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: setCurrency,
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: setCurrency,
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);
  } catch (e) {
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';

    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);
  }
};

export const dateFormatter = (dt: Date) => {
  let date = dt;

  if (typeof dt == 'string') {
    date = new Date(dt);
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDay = +day < 10 ? `0${day}` : day;
  const formattedDate = `${formattedDay}-${monthNames[monthIndex]}-${year}`;
  return monthNames[monthIndex] ? formattedDate : 'Invalid Date';
};
