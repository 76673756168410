/* eslint-disable no-use-before-define */
import { toast } from 'react-hot-toast';
import create from 'zustand';
import axios, { AxiosError } from 'axios';
import {
  CustomErrorResponse,
  RentReportingStoreInterface,
  RequestPayload,
  UploadedDocumentsInterface,
} from '../utils/interfaces';
import { RefObject } from 'react';
import { persist } from 'zustand/middleware';
import APIProvider from '../utils/ApiProvider';
import { themeDetails, verifyRentReportToken } from '../utils/endpoints';

const defaultState = {
  rent_report_form_data: null,
  additional_data: null,
  clf_page: 0,
  attributes: '{}',
  locale: 'en-US',
  uploadedDocuments: [],
  loading: true,
  screen: 'default',
  declined: false,
  leftSubmitting: false,
  rightSubmitting: false,
  error_message: '',
  org_theme: null,
};

const ServiceStore = (set: any, get: any) => ({
  ...defaultState,
  setScreen: (screen: string) => {
    set({ screen });
  },
  setLoading: (loading: boolean) => {
    set({ loading });
  },
  scrollToTop: async (refObject: RefObject<HTMLDivElement> | null) => {
    if (refObject?.current) {
      refObject.current.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  setRentReportFormData: (data: any) => {
    const { rent_report_form_data } = get();

    set({ rent_report_form_data: { ...rent_report_form_data, ...data } });
  },
  setCLFPage: (page: number) => {
    set({ clf_page: page });
  },
  setCLFAttribute: (attributes: string) => {
    set({ attributes: attributes });
  },
  setUploadedDocuments: (document: UploadedDocumentsInterface) => {
    const { uploadedDocuments } = get();

    const updatedDocuments = uploadedDocuments.filter(
      (item: UploadedDocumentsInterface) => item.id !== document.id
    );

    set({ uploadedDocuments: [...updatedDocuments, document] });
  },
  declineRequest: async (status: string) => {
    const { rent_report_form_data } = get();

    const payload = {
      status: status,
    };

    set({ leftSubmitting: true });
    (await APIProvider(
      verifyRentReportToken(rent_report_form_data?.token),
      'patch',
      payload
    )
      .then(() => {
        set({ leftSubmitting: false, screen: 'decline' });
      })
      .catch((err) => {
        set({ leftSubmitting: false });
        handleError(err as AxiosError<CustomErrorResponse>);
      })) as any;
  },
  submitRequest: async () => {
    const { attributes, additional_data, rent_report_form_data } = get();
    set({ rightSubmitting: true });

    const payload: RequestPayload = {
      status: 'approved',
    };

    if (attributes !== '{}') {
      payload.verifier_data = {
        ...JSON.parse(attributes)[additional_data?.meta?.name as string],
      };
    }

    APIProvider(
      verifyRentReportToken(rent_report_form_data?.token),
      'patch',
      payload
    )
      .then(() => {
        set({ screen: 'success' });
      })
      .catch((err) => {
        console.log(err);
        set({ rightSubmitting: false });
        handleError(err as AxiosError<CustomErrorResponse>);
      });
  },
  getOrgThemeDetails: async (slug: string) => {
    APIProvider(themeDetails(slug), 'get')
      .then((response: any) => {
        // console.log(response.data);
        set({
          org_theme: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getReportDetails: async (token: string, languageSwitch = false) => {
    const { screen } = get();

    if (languageSwitch === false) {
      set({ loading: true });
    }

    try {
      const data = (await APIProvider(
        verifyRentReportToken(token),
        'get'
      )) as any;

      set({
        loading: false,
        rent_report_form_data: { ...data?.data, token },
        country:
          data?.data.organization.country === 'NGA'
            ? data?.data.organization.country.slice(0, -1)
            : data?.data.organization.country,
      });

      if (data?.data.service.verifier_attribute?.default_value) {
        set({
          additional_data: data?.data.service.verifier_attribute?.default_value,
        });
      }

      if (screen === 'error') {
        set({
          screen: 'default',
        });
      }
    } catch (error: any) {
      set({
        loading: false,
        screen: 'error',
        error_message: error.response?.data
          ? error.response.data.message
          : error.message,
      });
      // await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
});

const handleError = async (
  error: AxiosError<CustomErrorResponse>
): Promise<string> => {
  if (axios.isAxiosError(error)) {
    const responseMessage = error.response?.data?.message;

    if (responseMessage) {
      toast.error(responseMessage);
      return Promise.reject(new Error(responseMessage));
    }
  }

  if (error.message) {
    toast.error(error.message);
    return Promise.reject(new Error(error.message));
  }

  // Handle the case where neither error.isAxiosError nor error.message exists
  // Return a generic error message or handle the case accordingly
  toast.error('An error occurred.');
  return Promise.reject(new Error('An error occurred.'));
};

const ServiceStorage = {
  getItem: async (name: string) => {
    return sessionStorage.getItem(name);
  },
  setItem: async (name: string, value: string) => {
    const data = JSON.parse(value);
    sessionStorage.setItem(
      name,
      JSON.stringify({
        ...data,
        state: { ...data.state, loading: false },
      })
    );
  },
  removeItem: async (name: string) => {
    console.log(name);
  },
};

const useServiceStore = create<RentReportingStoreInterface>(
  persist(ServiceStore, {
    name: 'rent_report_data',
    getStorage: () => ServiceStorage,
  })
);

export default useServiceStore;
