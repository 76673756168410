/* eslint-disable no-use-before-define */
import { toast } from 'react-hot-toast';
import create from 'zustand';
import { default as APIProvider } from '../utils/ApiProvider';
import {
  verifyGuarantorToken,
  processGuarantor,
  postUploadFile,
  postUploadPhoto,
  addDocument,
  postInitCard,
  getAllBanks,
  postVerifyBankAccount,
  createMandate,
  verifyMandateOtp,
  guarantorSubmit,
  postVerifyCard,
} from '../utils/endpoints';
import axios, { AxiosError } from 'axios';
import {
  ActivateMandateInterface,
  AddDocument,
  AddMandateInterface,
  AllBanks,
  BorrowerDetailsResponse,
  BorrowerLoanDetailsInterface,
  CollectionMethod,
  CustomErrorResponse,
  GuarantorDataInterface,
  GuarantorStoreInterface,
  GuarantorTokenInterface,
  MandatePayload,
  ProcessGuarantorInterface,
  VerifyBankPayload,
  VerifybankInterface,
} from '../utils/interfaces';
import { translate } from '@lendsqr/lingua-react';
import { persist } from 'zustand/middleware';
import English from '../../public/english.svg';

const defaultState = {
  screen: 'default',
  loading: true,
  status: 'pending',
  submitting: false,
  leftSubmitting: false,
  rightSubmitting: false,
  appName: 'irorun',
  declined: false,
  verified: false,
  loanData: {},
  documents: [],
  cardInitData: {},
  cardInitStatus: '',
  collectionScreen: -1,
  banks: [],
  account_name: null,
  mandateInstruction: null,
  guarantorData: {},
  pageSection: '',
  language: 'en',
  languageName: 'English',
  languageFlag: English,
  calendarLocale: null,
  errorMessage: null,
  locale: navigator.language,
  access_token: null,
  guarantorSection: null,
  currency: null,
  guarantor_details: {},
};

const GuarantorStore = (set: any, get: any) => ({
  ...defaultState,
  setScreen: (screen: string) => {
    set({ screen });
  },
  setLoanData: (loanData: BorrowerLoanDetailsInterface) => {
    set({ loanData });
  },
  setStatus: (value: string) => {
    set({ status: value });
  },
  setInitStatus: (value: string) => {
    set({ cardInitStatus: value });
  },
  setInitData: (value: string) => {
    set({ cardInitData: value });
  },
  setLoading: (value: string) => {
    set({ loading: value });
  },
  setCard: (card_id: string | number) => {
    set({ card_id });
  },
  setCollectionScreen: (value: string) => {
    set({ collectionScreen: value });
  },
  setRemitaRef: (value: string) => {
    set({ remitaRef: value });
  },
  setGuarantorData: (value: GuarantorDataInterface) => {
    set({ guarantorData: value });
  },
  setLocale: (value: string) => {
    set({ locale: value });
  },
  setLanguage: (code: string, name: string, image: string) => {
    set({
      language: code,
      languageName: name,
      languageFlag: image,
    });
  },
  clearAccountName: () => {
    set({ account_name: null });
  },
  navigateCollectionMethod: (direction = 'forward') => {
    const { loanData, collectionScreen, submit, screen, initCard } = get();

    set({ submitting: true });

    let newScreen: number = collectionScreen + 1;
    let defaultScreen = 'success';
    const collectionMap = {
      debit_card: 'cards',
      direct_debit: 'mandate',
    };

    if (direction !== 'forward') {
      newScreen = collectionScreen - 1;
      defaultScreen = 'document';
    }

    if (screen === 'document') {
      initCard();
      return;
    }

    const collectionMethod: string[] =
      loanData.collection_method.split(',') || [];

    const method = collectionMethod[newScreen];

    const nextScreen =
      (collectionMap as CollectionMethod)[method] ?? defaultScreen;

    screen === 'success' && submit();
    set({ collectionScreen: newScreen, screen: nextScreen, submitting: false });
  },
  verifyToken: async (token: string, languageSwitch = false) => {
    const useToken = token;

    const { setGuarantorData } = get();

    set({ guarantorSection: 'Guarantor' });

    if (languageSwitch === false) {
      set({ loading: true });
    }

    try {
      const data = (await APIProvider(
        verifyGuarantorToken(useToken),
        'get'
      )) as BorrowerDetailsResponse;

      let repaymentMethod = '';
      if (
        data?.data?.attributes &&
        data?.data?.attributes['repayment-methods']
      ) {
        const collectionMethods =
          typeof data?.data?.attributes['repayment-methods'] === 'string'
            ? JSON.parse(data?.data?.attributes['repayment-methods'])
            : data?.data?.attributes['repayment-methods'];
        if (collectionMethods['debit-card']) {
          repaymentMethod = `${repaymentMethod}debit_card,`;
        }
        if (collectionMethods['direct-debit']) {
          repaymentMethod = `${repaymentMethod}direct_debit`;
        }
      }
      if (!repaymentMethod) {
        repaymentMethod = data?.data?.collection_method;
      }
      const storedData = localStorage.getItem('guarantorData');
      const retrievedData = JSON.parse(storedData as string);

      if (retrievedData) {
        setGuarantorData({
          address: retrievedData.address,
          email: retrievedData.email,
        });
      }

      set({
        loading: false,
        currency: data.data.currency,
        loanData: { ...data?.data, collection_method: repaymentMethod, token },
        access_token: token,
      });

      localStorage.setItem('details_token', token);
    } catch (error: any) {
      set({
        loading: false,
        screen: 'error',
        message: error.response?.data
          ? error.response.data.message
          : error.message,
      });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  declineLoan: async () => {
    set({ rightSubmitting: true });

    setTimeout(() => {
      set({ rightSubmitting: false, declined: true });
    }, 3000);
  },
  processGuarantor: async (payload: ProcessGuarantorInterface) => {
    const { loanData, initCard } = get();
    set({ submitting: true, guarantor_details: payload });

    try {
      const data = (await APIProvider(
        processGuarantor(loanData.token),
        'post',
        payload
      )) as GuarantorTokenInterface;

      localStorage.setItem('access_token', data.data.guarantor_token);

      if (!loanData.documents.length) {
        set({
          access_token: data.data.guarantor_token,
        });

        initCard();
        return;
      } else {
        set({
          submitting: false,
          access_token: data.data.guarantor_token,
          screen: 'document',
        });
      }
    } catch (error) {
      set({ submitting: false });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  getBanks: async () => {
    set({ submitting: true });
    try {
      const { data } = (await APIProvider(getAllBanks, 'get')) as AllBanks;
      set({
        submitting: false,
        banks: data.map((item) => {
          return {
            bank_name: item.code_description,
            bank_code: item.additional_code,
          };
        }),
      });
    } catch (error) {
      set({ submitting: false });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  verifyBank: async (payload: VerifyBankPayload) => {
    set({ submitting: true });
    try {
      const { data } = (await APIProvider(
        postVerifyBankAccount,
        'post',
        payload
      )) as VerifybankInterface;
      set({
        submitting: false,
        verified: true,
        account_name: data.account_name,
      });
    } catch (error) {
      set({ submitting: false });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  addMandate: async (payload: MandatePayload) => {
    const { loanData } = get();
    set({ submitting: true });

    try {
      const { data } = (await APIProvider(
        createMandate,
        'post',
        payload
      )) as AddMandateInterface;
      set({
        submitting: false,
        mandateInstruction: data,
        mandate_id: data.id,
        screen:
          loanData.attributes?.direct_debit_provider === 'nibss-easypay'
            ? 'success'
            : 'post_mandate',
      });
    } catch (error) {
      set({ submitting: false });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  activateMandate: async (body: {}) => {
    let proceed = false;
    set({ submitting: true });

    try {
      const { status } = (await APIProvider(
        verifyMandateOtp,
        'post',
        body
      )) as ActivateMandateInterface;
      if (status === 'success') {
        proceed = true;
      }
    } catch (error) {
      await handleError(error as AxiosError<CustomErrorResponse>);
    } finally {
      set({ submitting: false });
    }
    return proceed;
  },
  uploadDocument: async (file: Blob, typeId: number, cb: () => void) => {
    const { setStatus } = get();

    const formData = new FormData();
    if (file.type.includes('image')) {
      formData.append('file', file, 'user-document.png');
    } else {
      formData.append('file', file);
    }

    const currentState = get();

    try {
      setStatus('loading');
      set({ docUploadStatus: 'loading' });
      const contentType = {
        ContentType: 'multipart/form-data',
      };

      const upload = await APIProvider(
        file.type === 'application/pdf' ? postUploadFile : postUploadPhoto,
        'post',
        formData,
        contentType
      ).catch((error) => {
        setStatus('failed');
        throw new Error(
          'Error uploading file: ' + error?.message ||
            error?.response?.data?.message ||
            error?.response?.message
        );
      });
      const response = (await APIProvider(addDocument, 'post', {
        url: (upload as AddDocument).data.url,
        type_id: typeId,
      })) as AddDocument;

      set({
        submitting: false,
        documents: [...currentState.documents, response.data],
        screen: 'document',
      });
      typeof cb === 'function' && cb();
      setStatus('success');
    } catch (error) {
      setStatus('failed');
      set({ submitting: false });
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  initCard: async () => {
    try {
      set({ cardInitStatus: 'loading' });
      const response = (await APIProvider(
        postInitCard(import.meta.env.VITE_CALLBACK_URL as string),
        'post'
      )) as any;
      const { reference, authorization_url, ...params } = response.data;

      set({
        cardInitData: { ...params },
        cardInitStatus: 'confirm',
      });

      localStorage.setItem('references', reference);
      window.location.href = authorization_url;
    } catch (error: any) {
      set({
        cardInitStatus: 'error',
        loading: false,
        screen: 'error',
        message: error.response?.data
          ? error.response.data.message
          : error.message,
      });
      // eslint-disable-next-line no-use-before-define
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  submit: async (card_id: number) => {
    try {
      await APIProvider(guarantorSubmit, 'post', { card_id }).then(() => {});
    } catch (error) {
      // eslint-disable-next-line no-use-before-define
      await handleError(error as AxiosError<CustomErrorResponse>);
    }
  },
  verifyCard: async () => {
    const { setInitStatus } = get();
    const payment_reference = localStorage.getItem('references');

    await APIProvider(postVerifyCard, 'post', { reference: payment_reference })
      .then(async () => {
        const sucessMessage = await translate(
          'verification-web-app-add-card-success'
        );
        toast.success(sucessMessage as string);
        setInitStatus('success');
      })
      .catch((e) => {
        setInitStatus('error');
        toast.error(
          e.message || translate('verification-web-app-add-card-error')
        );
      });
  },
});

const handleError = async (
  error: AxiosError<CustomErrorResponse>
): Promise<string> => {
  if (axios.isAxiosError(error)) {
    const responseMessage = error.response?.data?.message;

    if (responseMessage) {
      toast.error(responseMessage);
      return Promise.reject(new Error(responseMessage));
    }
  }

  if (error.message) {
    toast.error(error.message);
    return Promise.reject(new Error(error.message));
  }

  // Handle the case where neither error.isAxiosError nor error.message exists
  // Return a generic error message or handle the case accordingly
  toast.error('An error occurred.');
  return Promise.reject(new Error('An error occurred.'));
};

const GuarantorStorage = {
  getItem: async (name: string) => {
    return sessionStorage.getItem(name);
  },
  setItem: async (name: string, value: string) => {
    const data = JSON.parse(value);
    sessionStorage.setItem(
      name,
      JSON.stringify({
        ...data,
        state: { ...data.state, loading: false },
      })
    );
  },
  removeItem: async (name: string) => {
    console.log(name);
  },
};

const useGuarantorStore = create<GuarantorStoreInterface>(
  persist(GuarantorStore, {
    name: 'guarantor_data',
    getStorage: () => GuarantorStorage,
  })
);

export default useGuarantorStore;
