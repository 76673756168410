import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import useGuarantorStore from '../../state/Guarantor.store';
import French from '../../../public/french.svg';
import English from '../../../public/english.svg';
import Swahili from '../../../public/swahili.svg';
import Rwanda from '../../../public/rwanda.svg';
import '../../assets/styles/nav.scss';
import useEmailStore from '../../state/Email.store';
import ArrowDown from '../../public/arrow_down.svg';
import { LanguageSwitchContext } from '../../utils/contexts';
import { BorrowerLoanDetailsInterface } from '../../utils/interfaces';

interface LanguageProps {
  name: string;
  code: string;
  locale?: string;
  image: string;
  calendarLocale?: string;
  countryCode?: string;
}

function Header() {
  const [showLanguageDrop, setShowLanguageDrop] = useState(false);
  const [searchParams] = useSearchParams();
  const [languageSwitchLoader, setLanguageSwitchLoader] = useState(false);

  const token = searchParams.get('token');

  const [
    loanData,
    languageName,
    setLanguage,
    setLocale,
    verifyToken,
    guarantorSection,
    languageFlag,
  ] = useGuarantorStore(
    useCallback(
      (s) => [
        s.loanData as BorrowerLoanDetailsInterface,
        s.languageName,
        s.setLanguage,
        s.setLocale,
        s.verifyToken,
        s.guarantorSection,
        s.languageFlag,
      ],
      []
    )
  );

  const [setSection] = useEmailStore(useCallback((s) => [s.setSection], []));

  const languageArray = [
    {
      name: 'English',
      code: 'en',
      locale: 'en-US',
      image: English,
    },
    {
      name: 'French',
      code: 'fr',
      locale: 'fr-FR',
      image: French,
    },
    {
      name: 'Swahili',
      code: 'sw',
      locale: 'sw-KE',
      image: Swahili,
    },
    {
      name: 'Kinyarwanda',
      code: 'rw',
      locale: 'rw-RU',
      image: Rwanda,
    },
  ];

  const { setCurrentLocale } = useContext(LanguageSwitchContext);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const dropdown = dropdownRef.current;

    const handleClick = (e: MouseEvent) => {
      if (!dropdown?.contains(e.target as Node)) {
        setShowLanguageDrop(false);
        console.log(dropdown);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleLanguageClick = (language: LanguageProps) => {
    setLanguageSwitchLoader(true);

    setLanguage(language.code, language.name, language.image);
    setLocale(language.locale as string);
    setCurrentLocale(language.calendarLocale as string);

    setTimeout(() => {
      setLanguageSwitchLoader(false);
    }, 3000);

    setShowLanguageDrop(false);

    if (guarantorSection) {
      const languageSwitch = true;
      verifyToken(token as string, languageSwitch);
    } else {
      setSection();
    }
  };

  return (
    <nav className="navbar auth-nav notfound_nav">
      <a className="navbar-brand">
        {loanData.logo && (
          <img id="logo" src={loanData.logo} alt="Logo" width="auto" />
        )}{' '}
      </a>
      <div className="language-container" ref={dropdownRef}>
        <div
          className="language-wrapper"
          onClick={() => setShowLanguageDrop(!showLanguageDrop)}
        >
          {languageSwitchLoader && (
            <output
              className="spinner-border text-primary"
              style={{ width: '10px', height: '10px', marginRight: '10px' }}
            ></output>
          )}
          <img
            className="flag"
            src={languageFlag as string}
            alt={languageName as string}
          />
          <p>{languageName}</p>
          <img src={ArrowDown} alt="arrow down" className="toggle-icon" />
        </div>
        {showLanguageDrop && (
          <div className="language-drop-container">
            {languageArray.map((language) => {
              return (
                <div className="language-wrapper" key={language.name}>
                  <img
                    className="flag"
                    src={language.image}
                    alt={language.name}
                  />
                  <p
                    className="language-drop-item"
                    onClick={() => handleLanguageClick(language)}
                  >
                    {language.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Header;
