import { useState, useEffect, useRef, FC, useCallback } from 'react';
import { HStack, Text, Icon, Flex, VStack, Spinner } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import shallow from 'zustand/shallow';
import WebcamCapture, { FileProps } from '../../base/Camera';
import {
  AddPhoto,
  FieldInterface,
  UploadedDocumentsInterface,
} from '../../../utils/interfaces';
import { postCLFUploadPhoto } from '../../../utils/endpoints';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from '@lendsqr/lingua-react';
import APIProvider from '../../../utils/ApiProvider';
import useServiceStore from '../../../state/Service.store';
import { IoMdInformationCircle } from 'react-icons/io';
import CameraIcon from '../../../assets/camera-solid.svg';

export interface GeoLocationData {
  accuracy: number;
  altitude: null | number;
  altitudeAccuracy: null | number;
  heading: null | number;
  latitude: number;
  longitude: number;
  speed: null | number;
}

interface ImageCaptureProps {
  field: FieldInterface;
  setValue: any;
}

const ImageCapture: FC<ImageCaptureProps> = ({ field, setValue }) => {
  const { translate } = useTranslation();
  const videoConstraints = {
    width: 250,
    height: 250,
    facingMode: field.name.includes('environment')
      ? { exact: 'environment' }
      : 'user',
  };

  const [showWebCamModal, setShowWebCamModal] = useState<boolean>(false);
  const [document, setDocument] = useState<
    UploadedDocumentsInterface | undefined
  >(undefined);
  const cancelCameraRef = useRef<HTMLElement>(null);
  const [file, setFile] = useState<FileProps | null>(null);

  const [uploadedDocuments, locale, setUploadedDocuments] = useServiceStore(
    (state) => [
      state.uploadedDocuments,
      state.locale,
      state.setUploadedDocuments,
    ],
    shallow
  );

  const submit = async () => {
    let fileBlob: Blob | undefined;
    setDocument(undefined);
    console.log();

    if (file?.method === 'selfie') {
      await fetch(file.preview)
        .then((res) => res.blob())
        .then((blob) => {
          fileBlob = blob;
        })
        .catch((e) => {
          console.error(e);
          toast.error(`There was an error uploading your photo - ${e.message}`);
        });

      if (!fileBlob) {
        return;
      }
    }

    try {
      const formData = new FormData();
      if (file?.method === 'selfie') {
        formData.append('file', fileBlob as Blob, 'user-selfie.png');
      } else {
        formData.append('file', file?.file as File);
      }

      console.log('Uploading file: ', file);
      const xLocale = {
        XLocale: locale,
      };

      const uploadRes = async () => {
        const contentType = {
          ContentType: 'multipart/form-data',
        };

        const res = await APIProvider(postCLFUploadPhoto, 'post', formData, {
          ...contentType,
          ...xLocale,
        });

        return res;
      };

      const { data } = (await uploadRes()) as AddPhoto;
      setValue(field.id, data.url);

      const document = {
        data: {
          name: 'updates', // fileBlob.name as string
          type: fileBlob?.type as string,
          url: data.url,
        },
        id: field.id,
        status: 'success',
      };
      setDocument(document);
      setUploadedDocuments(document);
    } catch (error: unknown) {
      setFile(null);

      if (typeof error === 'object' && error !== null) {
        const errorObj = error as { message: string };
        if (errorObj.message) {
          toast.error(errorObj.message);
        }
      }
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        const errorMessage = await translate(
          'invitation-web-app-file-type-error'
        );
        await toast.error(errorMessage);
        return;
      } else if (acceptedFiles[0].size / 1000000 > 5) {
        const errorMessage = await translate(
          'invitation-web-app-file-size-error'
        );
        await toast.error(errorMessage);
        return;
      }
      try {
        setDocument(undefined);

        const fileBlob = acceptedFiles[0];
        console.log('Blobber', fileBlob);

        // upload photo to server
        console.log('Uploading file: ', fileBlob);

        const xLocale = {
          XLocale: locale,
        };

        const formData = new FormData();

        formData.append('file', fileBlob, 'user-document.png');

        const uploadRes = async () => {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await APIProvider(postCLFUploadPhoto, 'post', formData, {
            ...contentType,
            ...xLocale,
          });

          return res;
        };

        const { data } = (await uploadRes()) as AddPhoto;
        setValue(field.id, data.url);

        const document = {
          data: {
            name: fileBlob.name,
            type: fileBlob.type,
            url: data.url,
          },
          id: field.id,
          status: 'success',
        };
        setDocument(document);
        setUploadedDocuments(document);
        setFile(null);
      } catch (error: { message: string } | any) {
        toast.error(error.message);
      }
    },
    []
  );

  const acceptedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  useEffect(() => {
    if (uploadedDocuments) {
      setDocument(
        uploadedDocuments.find(
          (document: UploadedDocumentsInterface) => document.id === field.id
        )
      );
    }
  }, []);

  return (
    <>
      {/* <HStack gap={'0.2rem'} alignItems={'center'} justifyContent={'center'}>
        <FormLabel
          lineHeight="1"
          mb="4px"
          mx="0"
          fontSize="0.885rem"
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {field.label}
        </FormLabel>
        {field.validation.required ? (
          <Image src="/required.png" width={'7px'} height={'7px'} />
        ) : (
          ''
        )}
      </HStack> */}
      <VStack
        cursor="pointer"
        onClick={() => {
          setShowWebCamModal(true);
        }}
        justify="center"
        flex="1"
      >
        <Flex
          w="9.375rem"
          h="9.375rem"
          borderRadius="50%"
          bg="#D3FFF4"
          justifyContent="center"
          alignItems="center"
        >
          {file || document ? (
            <Spinner size="xl" />
          ) : (
            <Flex
              color="brand.blue_main"
              w="2.5rem"
              justifyContent="center"
              alignItems="center"
            >
              <img src={CameraIcon} alt="Camera Icon" />
            </Flex>
          )}
        </Flex>
      </VStack>

      {file ? (
        <HStack
          w="fit-content"
          my="1rem"
          mx="auto"
          rounded="4px"
          bg="#D3FFF4"
          p="1rem"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 0C3.8033 0 0 3.8033 0 8.5C0 13.1967 3.8033 17 8.5 17C13.1967 17 17 13.1967 17 8.5C17 3.8033 13.1967 0 8.5 0ZM13.5285 6.94294L8.01501 12.4565C7.60661 12.8649 6.94294 12.8649 6.53453 12.4565L6.15165 12.0736L3.54805 9.46997C3.13964 9.06156 3.13964 8.3979 3.54805 7.98949L3.93093 7.60661C4.33934 7.1982 5.003 7.1982 5.41141 7.60661L7.27477 9.46997L11.6652 5.07958C12.0736 4.67117 12.7372 4.67117 13.1456 5.07958L13.5285 5.46246C13.9369 5.87087 13.9369 6.53453 13.5285 6.94294Z"
              fill="#31C5A1"
            />
          </svg>

          <Text fontSize="0.75rem" color="brand.black">
            {translate('invitation-web-app-selfie-upload-success')}
          </Text>
        </HStack>
      ) : (
        <HStack
          rounded="4px"
          bg="#D3FFF4"
          p="1rem"
          w="fit-content"
          my="1rem"
          maxW="80%"
          mx={'auto'}
        >
          <Icon mt="0.2rem" as={IoMdInformationCircle} />
          <Text {...getRootProps()} fontSize="0.75rem">
            {translate('invitation-web-app-selfie-upload-tooltip')}{' '}
            <Text
              textDecoration={'underline'}
              display={'inline'}
              color={'#24C6A1'}
              cursor="pointer"
              marginRight={'5px'}
              onClick={open}
            >
              {translate('invitation-web-app-selfie-upload-hyperlink')}
            </Text>
            {''}
            {translate('invitation-web-app-selfie-upload-tooltip-2')}
            {''}
            <input type="file" {...getInputProps()} />
          </Text>
        </HStack>
      )}

      {showWebCamModal && (
        <WebcamCapture
          showWebCamModal={showWebCamModal}
          setShowWebCamModal={setShowWebCamModal}
          setCapturedImage={setFile}
          capturedImage={file?.preview as string}
          cancelCameraRef={cancelCameraRef}
          handleSave={() => {
            setShowWebCamModal(false);
            submit();
          }}
          videoConstraints={videoConstraints}
        />
      )}
    </>
  );
};

export default ImageCapture;
