import CardBtn from '../CardBtn/CardBtn';
import React, { useCallback } from 'react';
import Button from '../form/Button';
import useGuarantorStore from '../../state/Guarantor.store';
import { useTranslation } from '@lendsqr/lingua-react';
import { BorrowerLoanDetailsInterface } from '../../utils/interfaces';

export function GuarantorDocument() {
  const { translate } = useTranslation();
  const [
    setScreen,
    loanData,
    docsUploaded,
    navigateCollectionMethod,
    submitting,
  ] = useGuarantorStore(
    useCallback(
      (s) => [
        s.setScreen,
        s.loanData as BorrowerLoanDetailsInterface,
        s.documents,
        s.navigateCollectionMethod,
        s.submitting,
      ],
      []
    )
  );

  const getDocumentStatus = useCallback(
    (docTypeId: number) => {
      const doc = docsUploaded.find((d) => d.type_id === docTypeId);
      if (!doc) {
        return null;
      }
      return doc.status;
    },
    [docsUploaded]
  );

  const validateDocument = useCallback(() => {
    for (const doc of loanData.documents) {
      if (!getDocumentStatus(doc.id)) {
        return true;
      }
    }
    return false;
  }, [docsUploaded]);

  return (
    <div className="accept-request" id="accept-page">
      <div className="validate_form">
        <div>
          <div className="mb-5">
            <p className="text-black fs-5  fw-bold">
              {translate('verification-web-app-add-document')}
            </p>
            <small className="text-black-50">
              {translate('verification-web-app-add-document-description')}
            </small>
          </div>

          <div className="card-btns">
            {loanData.documents.map((docType) => {
              const status = getDocumentStatus(docType.id);
              return (
                <CardBtn
                  status={status}
                  key={docType.id}
                  fileName={docType?.description || docType.name}
                  {...docType}
                />
              );
            })}
          </div>

          <div className="buttonGroup">
            <Button
              onClick={() => setScreen('accept')}
              className="btn-dark"
              type="button"
            >
              {translate('verification-web-app-back')}
            </Button>
            <Button
              isDisabled={validateDocument()}
              isLoading={submitting}
              onClick={() => navigateCollectionMethod('forward')}
              type="button"
              className="btn-primary"
            >
              {translate('verification-web-app-continue')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
