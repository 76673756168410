import { useCallback } from 'react';
import useServiceStore from '../../state/Service.store';

const DeclineRequest = () => {
  const [rent_report_form_data] = useServiceStore(
    useCallback((s) => [s.rent_report_form_data], [])
  );

  return (
    <div className="decline-request" id="decline-page">
      <div className="validate_success" id="decline-success">
        <div className="icon">
          <img src="/success-icon.svg" alt="" />
        </div>
        <h3 className="validate_form-header">
          Decline{' '}
          <span
            id="  style={{ textTransform: 'capitalize' }}"
            style={{ textTransform: 'capitalize' }}
          >{`${rent_report_form_data?.user.first_name} ${rent_report_form_data?.user.last_name}'s `}</span>
          {`${rent_report_form_data?.service.attribute.name}`} Request
        </h3>
        <p className="validate_form-sub">
          You have successfully declined to approve a request on{' '}
          {`${rent_report_form_data?.organization.name}`}
        </p>
      </div>
    </div>
  );
};

export { DeclineRequest };
