import React, { useCallback, useState } from 'react';
import useGuarantorStore from '../../state/Guarantor.store';
import Button from '../form/Button';
import { useTranslation } from '@lendsqr/lingua-react';
import { BorrowerLoanDetailsInterface } from '../../utils/interfaces';

const DeclineLoan = () => {
  const { translate } = useTranslation();
  const [reason, setReason] = useState('');
  const [
    leftSubmitting,
    rightSubmitting,
    declineLoan,
    declined,
    setScreen,
    loanData,
  ] = useGuarantorStore(
    useCallback(
      (s) => [
        s.leftSubmitting,
        s.rightSubmitting,
        s.declineLoan,
        s.declined,
        s.setScreen,
        s.loanData as BorrowerLoanDetailsInterface,
      ],
      []
    )
  );

  return (
    <div className="decline-request" id="decline-page">
      {declined ? (
        <div className="validate_success" id="decline-success">
          <div className="icon">
            <img src="/success-icon.svg" />
          </div>
          <h5 className="validate_form-header ">
            {translate('verification-web-app-request-successful')}
          </h5>
          <p className="validate_form-sub">
            {translate('verification-web-app-request-successful-description')}{' '}
            <span id="borrowerName5" className="bold"></span>{' '}
            {translate(
              'verification-web-app-request-successful-description-2',
              { organisation_name: `${loanData.org_name}` }
            )}
          </p>
        </div>
      ) : (
        <div className="validate_form " id="decline_form">
          <h3 className="validate_form-header">
            {translate('verification-web-app-decline-loan', {
              borrower: `${loanData.first_name}`,
            })}{' '}
          </h3>
          <p className="validate_form-sub">
            {translate('verification-web-app-decline-loan-reason', {
              Borrower: `${loanData.first_name} ${loanData.last_name}`,
            })}
          </p>
          <div className="validate_form-body">
            <form action="" className="decline-form">
              <p className="header">
                {translate('verification-web-app-decline-reason-form')}
              </p>
              <div className="form-group">
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="form-control"
                  id="reason"
                ></textarea>
              </div>
              <div className="buttonGroup">
                <Button
                  isLoading={leftSubmitting}
                  onClick={() => setScreen('default')}
                  className="btn-dark"
                  type="button"
                >
                  {translate('verification-web-app-back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={rightSubmitting}
                  onClick={() => declineLoan(reason)}
                  className="btn-primary"
                >
                  {translate('verification-web-app-submit')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export { DeclineLoan };
