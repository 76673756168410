import moment from 'moment';
import toast from 'react-hot-toast';
import { AdditionalReportDataInterface } from './interfaces';
import { useTranslation } from '@lendsqr/lingua-react';

const qs = new URLSearchParams(window.location.search);
// const languageCode = qs.get('language_code');
const locale = qs.get('locale') ?? navigator.language;
const currency = qs.get('currency') ?? 'NGN';
// console.log(languageCode, locale, currency);

export const money = (amount: number | string, setCurrency: string) => {
  try {
    // Extract numeric part from string if input is in format "$1000"
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: setCurrency,
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';
    else {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: setCurrency,
        minimumFractionDigits: 0, // Set to 0 to hide decimal places
      }).format(numericAmount);
    }
  } catch (e) {
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';
    else {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        minimumFractionDigits: 0, // Set to 0 to hide decimal places
      }).format(numericAmount);
    }
  }
};

export const formatNumber = (number: number | string = 0) => {
  const numericAmount =
    typeof number === 'string'
      ? parseFloat(number.replace(/[^\d.-]/g, ''))
      : Number(number);

  if (isNaN(numericAmount)) {
    return ''; // Handle invalid input
  }

  return new Intl.NumberFormat('en-US').format(numericAmount);
};

export const periodToFrequency = (period = '') => {
  switch (period) {
    case 'day':
    case 'days':
      return 'daily';

    case 'week':
    case 'weeks':
      return 'weekly';

    case 'month':
    case 'months':
      return 'monthly';

    case 'year':
    case 'years':
      return 'yearly';
    default:
      return period;
  }
};

export const repaymentDates = (date: string | number | Date) => {
  try {
    let d = date;
    if (typeof date === 'string') {
      d = new Date(date);
    }
    return moment(d).format('MMM DD, yyyy');
  } catch (e) {
    console.warn(`[repaymentDate] date-> ${date}`);
    console.warn(e);
    return date?.toString();
  }
};

export const base64ToFile = (
  url: string,
  filename: string,
  mimeType: string
) => {
  const { translate } = useTranslation();

  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    })
    .catch((e) => {
      toast.error(translate('invitation-web-app-file-upload-error'));
      console.log(e, 'Error converting to base 64');
    });
};

export const fullDateTimeFormat = (date: string | number) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  });

export function validateLocaleAndCurrency() {
  try {
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(1000);

    return { locale, currency };
  } catch (error) {
    return { locale: 'en-NG', currency: 'NGN' };
  }
}

export function validateLocale() {
  try {
    // Attempt to create a NumberFormat object with the provided locale
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'USD', // You can use any valid currency code here
    }).format(1000); // You can use any sample number for testing

    return locale; // If no error is thrown, the locale is valid
  } catch (error) {
    console.error('Error:', error); // Log the error for debugging
    return 'en-NG'; // Default to 'en-NG' for an invalid locale
  }
}

export function validateCurrency() {
  try {
    // Attempt to create a NumberFormat object with the provided currency
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(1000); // You can use any sample number for testing

    return currency; // If no error is thrown, the currency is valid
  } catch (error) {
    console.error('Error:', error); // Log the error for debugging
    return 'NGN'; // Default to 'NGN' for an invalid currency
  }
}

export const showCLF = (
  additional_loan_data: AdditionalReportDataInterface
) => {
  const showConfigurableForm =
    additional_loan_data?.meta?.status.toLowerCase() === 'active' || false;

  return showConfigurableForm;
};

export const isObjectNotEmpty = <T extends object>(obj: T): boolean => {
  // do not go further if obj is null
  if (!obj) {
    return false;
  }
  return Object.keys(obj).length > 0;
};

export const fileMimeTypes = [
  'mp3',
  'wav',
  'ogg',
  'aac',
  'mp4',
  'webm',
  'ogg',
  'pdf',
];

export const yesterday = moment().subtract(1, 'day');
export const valid = (current: moment.Moment): boolean => {
  return current.isAfter(yesterday);
};

export const dataObject = {
  address: '15 Alhaji Bankole Crescent, Ikeja, Lagos',
  current_employer: '',
  educational_attainment: '',
  employment_category: '',
  employment_status: '',
  gender: 'Female',
  latitude: 0,
  longitude: 0,
  marital_status: 'Single',
  monthly_net_income: '',
  no_of_dependent: '3',
  product_id: '0',
  proposed_payday: '',
  proposed_tenor: 0,
  proposed_tenor_period: '',
  purpose: '',
  requested_amount: 0,
  sector_of_employment: '',
  type_of_residence: '',
  work_email: '',
  work_start_date: '',
};

export const dateFormatter = (dt: Date) => {
  let date = dt;

  if (typeof dt == 'string') {
    date = new Date(dt);
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDay = +day < 10 ? `0${day}` : day;
  const formattedDate = `${formattedDay}-${monthNames[monthIndex]}-${year}`;
  return monthNames[monthIndex] ? formattedDate : 'Invalid Date';
};

export function convertSnakeToLabel(snakeCaseString: string) {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const formatDateValidation = (validationString: any, type: string) => {
  if (!validationString) return '';
  if (type === 'fixed')
    return moment(validationString).format('YYYY/MM/DD HH:mm:ss');
  const [value, unit, action] = validationString.split(' ');

  if (action === 'after') {
    return moment().add(value, unit).format('YYYY/MM/DD HH:mm:ss');
  }
  if (action === 'before') {
    return moment().subtract(value, unit).format('YYYY/MM/DD HH:mm:ss');
  }
  return moment().format('YYYY/MM/DD HH:mm:ss');
};

export const isOnlyCLF = (product: any) => {
  if (product?.attributes && 'show_only_clf' in product.attributes) {
    return !!product.attributes.show_only_clf;
  }
  return (
    product?.attributes?.additional_loan_data?.meta?.show_only_clf?.toLowerCase() ===
    'true'
  );
};
