import { useTranslation } from '@lendsqr/lingua-react';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { pickBy, identity } from 'lodash';
import get from 'lodash.get';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  Box,
  FormErrorMessage,
  Spinner,
} from '@chakra-ui/react';
import {
  FieldError,
  Merge,
  FieldErrorsImpl,
  Controller,
  Control,
  DeepMap,
  UseFormRegisterReturn,
} from 'react-hook-form';
import moment from 'moment';
import { FormDataInterface } from '../../../utils/interfaces';
import toast from 'react-hot-toast';

const replaceDoubleBraces = (str: string, result: any) => {
  return str.replace(/{{(.+?)}}/g, (_, g1) => result || g1);
};

type FieldProps = {
  name: string;
  label: string;
  className?: string;
  info?: React.ReactNode;
  parameters: Record<string, any>;
  isInvalid: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined;
  id: string;
  required?: boolean;
  register?: any;
  setValue?: any;
  getValue?: any;
  setError?: any;
  clearErrors?: any;
  control: Control<FormDataInterface>;
  errors: DeepMap<FormDataInterface, FieldError>;
  formHook?: UseFormRegisterReturn<string>;
};

const APITextField = ({
  label,
  name,
  info,
  parameters,
  isInvalid,
  id,
  required,
  setError,
  setValue,
  control,
  clearErrors,
  errors,
  formHook,
}: FieldProps) => {
  const { translate } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleTriggerResponseError = (error: any) => {
    parameters.assign.forEach((assign: { key: string; value: string }) => {
      setValue(assign.value, '');
    });
    toast.error(error?.response?.data?.message || error.message);

    setLoading(false);
  };

  const triggerResponse = useCallback(async (data: any) => {
    if (!data) {
      setError(id, {
        type: 'manual',
        message: `${name} is required`,
      });

      return;
    }

    const params: {
      url: string;
      method: string;
      headers: any;
      params?: string;
      data?: any;
    } = {
      url: replaceDoubleBraces(parameters.url, data),
      method: parameters.method,
      headers: parameters.headers,
    };

    if (parameters.query) {
      params.params = JSON.parse(
        replaceDoubleBraces(JSON.stringify(parameters.query), data)
      );
    }

    if (parameters.headers) {
      params.headers = JSON.parse(
        replaceDoubleBraces(JSON.stringify(parameters.headers), {
          ...data,
          org_id: 0,
        })
      );
    }

    if (parameters.body) {
      params.data = {};
      const keys = JSON.parse(
        replaceDoubleBraces(JSON.stringify(parameters.body), data)
      );
      params.data = keys;
    }

    const cleanedObject = pickBy(params, identity);

    console.log(params, cleanedObject);

    try {
      clearErrors(id);
      setLoading(true);
      await axios(cleanedObject)
        .then((response) => {
          if (response.data.data.message !== null) {
            setError(id, {
              type: 'manual',
              message: `${response.data.data.message}`,
            });

            parameters.assign.forEach(
              (assign: { key: string; value: string }) => {
                setValue(assign.value, get('', assign.key));
              }
            );
            return;
          }

          parameters.assign.forEach(
            (assign: { key: string; value: string }) => {
              if (
                assign.value === 'loan_history' &&
                response.data.data.loanHistoryDetails
              ) {
                setValue(
                  assign.value,
                  response.data.data.loanHistoryDetails.length.toString()
                );
              } else if (
                assign.value === 'outstanding_loan_amount' &&
                response.data.data.loanHistoryDetails
              ) {
                setValue(
                  assign.value,
                  response.data.data.loanHistoryDetails.reduce(
                    (acc: any, history: any) => history.outstandingAmount + acc,
                    0
                  )
                );
              } else if (
                assign.value === 'last_payment_date' ||
                assign.value === 'last_disbursement_date'
              ) {
                setValue(
                  assign.value,
                  moment(
                    '03-01-2024 08:18:00+0000',
                    'DD-MM-YYYY HH:mm:ssZ'
                  ).format('YYYY-MM-DD[T]HH:mm:ss')
                );
              } else {
                setValue(assign.value, get(response.data, assign.key));
              }
            }
          );
        })
        .then(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      handleTriggerResponseError(err);
    }
  }, []);

  return (
    <FormControl isInvalid={!!isInvalid} id={id}>
      <HStack gap={'0.2rem'} alignItems={'flex-start'}>
        <FormLabel
          color={'rgba(33, 37, 41, 0.7)'}
          fontSize="0.885rem"
          mb="4px"
          mx="0"
          fontWeight={'light'}
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {label}
        </FormLabel>
        {required ? (
          <Image src="/required.png" width={'7px'} height={'7px'} />
        ) : (
          ''
        )}
      </HStack>
      <InputGroup size="md">
        <Controller
          control={control}
          name={id}
          rules={{
            required: required ? `${name} is required` : '',
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                h="54.6px"
                name={id}
                borderRadius={'15px'}
                {...formHook}
                variant={{ base: 'outline' }}
                border="1px solid #b0b8c8"
                sx={{
                  '&:disabled': {
                    border: '1px solid #b0b8c8',
                    bg: '#ECEEF0',
                    opacity: '1',
                  },
                }}
                _placeholder={{
                  fontSize: '0.7rem',
                  color: 'hsl(0, 0%, 50%)',
                  opacity: '0.5',
                }}
                id={id}
                type={'alphanumeric'}
                placeholder={name}
                onChange={(e) => {
                  if (!e.target.value) {
                    setError(id, {
                      type: 'manual',
                      message: `${name} is required`,
                    });
                  } else {
                    clearErrors(id);
                    onChange(e);
                  }
                }}
              />
              <InputRightElement width="4.5rem" h={'100%'} marginRight={'1rem'}>
                <Button
                  h="1.75rem"
                  size="sm"
                  minW={'72px'}
                  onClick={() => triggerResponse(value)}
                >
                  {loading ? (
                    <Spinner size="xs" />
                  ) : (
                    translate('invitation-web-app-submit')
                  )}
                </Button>
              </InputRightElement>
            </>
          )}
        />
      </InputGroup>
      <Box pt="0.5rem" h={`${errors ? '2rem' : '15px'}`}>
        <FormErrorMessage mt="0" fontSize="0.65rem">
          {errors[id]?.message as string}
        </FormErrorMessage>
      </Box>

      {info || null}
    </FormControl>
  );
};

export { APITextField };
