import { useEffect, useState, useCallback } from 'react';
import jwt from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { Input } from '../components/form';
import useEmailStore from '../state/Email.store';
import Button from '../components/form/Button';
import moment from 'moment';
import { useTranslation } from '@lendsqr/lingua-react';
import Header from '../components/header/Header';
import {
  OfficeEmailDetailsInterface,
  OfficeEmailValidatePayload,
} from '../utils/interfaces';
import {
  themeDetails,
  // getWebApp
} from '../utils/endpoints';
import { default as APIProvider } from '../utils/ApiProvider';

type Props = {
  is_borrower_email?: boolean;
};

const OfficeEmail = ({ is_borrower_email = false }: Props) => {
  const { translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<OfficeEmailDetailsInterface>();
  const [url, setUrl] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OfficeEmailValidatePayload>();
  const [
    rightSubmitting,
    leftSubmitting,
    cancelLoan,
    submitEmail,
    verifyBorrowerEmail,
    cancelled,
    successfull,
  ] = useEmailStore(
    useCallback(
      (s) => [
        s.rightSubmitting,
        s.leftSubmitting,
        s.cancelLoan,
        s.submitEmail,
        s.verifyBorrowerEmail,
        s.cancelled,
        s.successfull,
      ],
      []
    )
  );

  function openLinkInNewTab() {
    // Use window.open to open the URL in a new tab
    window.open(`https://${url}`, '_blank');
  }
  const token = searchParams.get('token');
  const onSubmit = (data: OfficeEmailValidatePayload) =>
    submitEmail(token as string, data);

  useEffect(() => {
    try {
      if (!token) {
        throw new Error(
          translate('verification-web-app-request-support-error')
        );
      }
      const decoded: OfficeEmailDetailsInterface = jwt(token);
      console.log('Decoded: ', decoded);

      if (
        moment
          .duration(moment.unix(decoded.exp).diff(moment()), 'milliseconds')
          .asSeconds() < 1
      ) {
        console.error('time expired');
      }
      console.log(decoded);
      setUserData(decoded);

      const orgThemeDetails = APIProvider(
        themeDetails(decoded.org_slug),
        'get'
      ).then((response: any) => {
        console.log(response.data);
        setUrl(response.data.domain);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
      console.log(orgThemeDetails);

      if (is_borrower_email) {
        const email = decoded?.email;
        if (!email) {
          throw new Error(
            translate('verification-web-app-request-support-error')
          );
        }

        verifyBorrowerEmail({ token, email })
          .catch(() => {
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setError(true);
      toast.error(translate('verification-web-app-invalid-token'));
    }
  }, []);

  let validateEmailElement;

  if (error) {
    validateEmailElement = (
      <div className="validate_success" id="failed">
        <div className="icon">
          <img alt="" src="/failed.svg" />
        </div>
        <h5 className="validate_form-header">
          {translate('verification-web-app-verification-failed')}
        </h5>
        <p className="validate_form-sub">
          {translate('verification-web-app-link-support-error')}
        </p>
      </div>
    );
  } else if (cancelled) {
    validateEmailElement = (
      <div className="validate_success" id="cancel-success">
        <div className="icon">
          <img alt="" src="/success-icon.svg" />
        </div>
        <h5 className="validate_form-header">
          {translate('verification-web-app-request-successful')}
        </h5>
        <p className="validate_form-sub">
          {translate('verification-web-app-verification-successful-info')}
        </p>
      </div>
    );
  } else if (successfull) {
    validateEmailElement = (
      <div className="validate_success" id="success">
        <div className="icon">
          <img alt="" src="/success-icon.svg" />
        </div>
        <h5 className="validate_form-header">
          {translate('verification-web-app-verification-successful')}
        </h5>
        {!is_borrower_email && (
          <p className="validate_form-sub">
            {translate('verification-web-app-email-verify-success')}
          </p>
        )}

        <Button
          className="btn-primary"
          type="submit"
          onClick={() => openLinkInNewTab()}
        >
          Log into the web app
        </Button>
      </div>
    );
  } else {
    validateEmailElement = (
      <div className="validate_form" id="main">
        <h3 className="validate_form-header">
          {translate('verification-web-app-email-verify')}
        </h3>
        <p className="validate_form-sub">
          {translate('verification-web-app-borrowe-details-form-prompt', {
            borrower: `${
              userData?.username ?? userData?.user?.first_name ?? ''
            }`,
          })}
          <span className="bold">
            {' '}
            {userData?.email ?? userData?.user?.email ?? ''}
          </span>
          , {translate('verification-web-app-borrower-details-form-prompt-2')}
        </p>
        <div className="validate_form-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="header">
              {translate('verification-web-app-form-prompt')}
            </p>
            <Input
              name="amount"
              errors={errors}
              type="number"
              placeholder={translate('verification-web-app-loan-amount')}
              register={register}
              validations={{
                required: translate('verification-web-app-loan-amount-error'),
              }}
            />
            <Input
              name="bvn"
              errors={errors}
              type="number"
              label={translate('verification-web-app-bvn-label')}
              placeholder={translate('verification-web-app-bvn-label')}
              register={register}
              validations={{
                required: translate('verification-web-app-bvn-error'),
              }}
            />
            <div className="buttonGroup">
              <Button
                onClick={() => cancelLoan()}
                className="btn-dark"
                type="button"
                isLoading={leftSubmitting}
              >
                {translate('verification-web-app-no-request')}
              </Button>
              <Button
                isLoading={rightSubmitting}
                type="submit"
                className="btn-primary "
              >
                {translate('verification-web-app-email-verify')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <output className="spinner-border text-primary"></output>
        </div>
      ) : (
        <>
          <Header />
          <main className="validate">
            <div className="validate_illustration">
              <img
                className="illustration"
                src="/download-icons81.svg"
                alt="Illustration"
              />
              <div className="tagline">
                <span>{translate('verification-web-app-powered-by')}</span>
                <img src="/logo.svg" className="tagline-logo" alt="Logo" />
              </div>
            </div>

            <div className="validate_textarea">
              <div className="verify-request">{validateEmailElement}</div>
            </div>
          </main>
        </>
      )}
    </div>
  );
};

export default OfficeEmail;
