import { useEffect, useState } from 'react';

const SmileIdentity = window.SmileIdentity;

type payloadType = {
  token: string;
  product: string;
  callback_url: string;
  job_id: string;
  partner_id: string;
  name: string;
  logo_url: string;
  policy_url: string;
  theme_color: string;
  [key: string]: any;
};

declare global {
  interface Window {
    ReactNativeWebView: any;
    lsqInitSmileid: payloadType;
    SmileIdentity: Function;
  }
}

function SmileID() {
  let loading = false;
  const [status, setStatus] = useState('Verify Smile Id');

  const configureSmileIdentityWebIntegration = (data: payloadType) => {
    // eslint-disable-next-line
    SmileIdentity({
      //ts-ignore
      ...data,
      token: data?.token,
      product: data?.product,
      callback_url: data?.callback_url,
      environment: 'live',
      job_id: data?.job_id,

      partner_details: {
        partner_id: data?.partner_id,
        name: data?.organization,
        logo_url: data?.logo_url,
        policy_url: data?.privacy_url,
        theme_color: data?.primary_color,
      },

      onSuccess: async (message: any) => {
        setStatus('Verified');
        loading = false;
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              method: 'success',
              arguments: message,
            })
          );
        }
      },

      onClose: () => {
        setStatus('Verify Smile Id');
        loading = false;
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              method: 'close',
              arguments: null,
            })
          );
        }
      },

      onError: (message: any) => {
        setStatus('Error Smile Id');
        loading = false;
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              method: 'error',
              arguments: message,
            })
          );
        }
      },
    });
  };

  const handleInitalization = async (data: payloadType) => {
    setStatus('Initializing...');
    configureSmileIdentityWebIntegration(data);
  };

  useEffect(() => {
    if (window?.lsqInitSmileid?.token) {
      handleInitalization(window?.lsqInitSmileid);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // border: '1px solid black',
        marginTop: '20px',
      }}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <button
          id="verify-with-smile-id"
          disabled={status !== 'Verify Smile Id'}
          onClick={async () => {
            handleInitalization(window?.lsqInitSmileid);
          }}
        >
          {status}
        </button>
      )}
    </div>
  );
}

export default SmileID;
