import React, { useCallback, useEffect } from 'react';
import useGuarantorStore from '../state/Guarantor.store';
import { useTranslation } from '@lendsqr/lingua-react';
import { BorrowerLoanDetailsInterface } from '../utils/interfaces';

const Success = () => {
  const { translate } = useTranslation();
  const [loanData, guarantorData, loading, verifyToken] = useGuarantorStore(
    useCallback(
      (s) => [
        s.loanData as BorrowerLoanDetailsInterface,
        s.guarantorData,
        s.loading,
        s.verifyToken,
      ],
      []
    )
  );

  useEffect(() => {
    const token = localStorage.getItem('details_token');
    verifyToken(token as string);
    localStorage.clear();
  }, []);
  return (
    <>
      {loading ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <output className="spinner-border text-primary"></output>
        </div>
      ) : (
        <div className="accept-request" id="accept-page">
          <div className="validate_success" id="approve-success">
            <div className="icon">
              <img src="/success-icon.svg" />
            </div>
            {loanData.collection_method.includes('direct_debit') &&
            loanData.attributes?.direct_debit_provider === 'nibss-easypay' ? (
              <>
                <h5 className="validate_form-header mt-2">
                  {translate('verification-web-app-mandate-email-sent')}
                </h5>
                <p className="validate_form-sub">
                  {translate('verification-web-app-guarantor-email', {
                    guarantor_email: `${guarantorData?.email}`,
                  })}
                </p>
              </>
            ) : (
              <>
                <h5 className="validate_form-header mt-2">
                  {translate('verification-web-app-request-successful')}
                </h5>
                <p className="validate_form-sub">
                  {translate('verification-web-app-guarantor-success')}{' '}
                  <span
                    id="borrowerName3"
                    className="bold ml-1"
                  >{`${loanData.first_name} ${loanData.last_name}`}</span>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { Success };
