import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

interface AppModalProps {
  // key: KeyType;
  show: boolean;
  close: () => void;
  description: string;
  children: React.ReactNode;
  isUpload?: boolean;
}

export default function Modal(props: AppModalProps) {
  return ReactDOM.createPortal(
    <AppModal {...props} />,
    modalRoot as HTMLElement
  );
}

function AppModal({
  show,
  close,
  description,
  children,
  isUpload = true,
}: Readonly<AppModalProps>) {
  useEffect(() => {
    const { body } = document;
    body.classList[show ? 'add' : 'remove']('modal-open');
  }, [show]);

  return (
    <>
      <div className={`modal-backdrop ${show ? 'show' : 'fade'}`} />
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        style={{ display: show ? 'block' : 'none' }}
        tabIndex={-1}
        aria-hidden={!show}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadDocLabel">
                {isUpload ? 'Upload -' : ''} {description}
              </h5>
              <button
                onClick={close}
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img width="20px" src="/cancel.png" alt="" />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
