import React, { useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from '@lendsqr/lingua-react';

const userConstraints = {
  width: 250,
  height: 250,
  facingMode: 'user',
};

export type FileProps = {
  file: File | string;
  method?: string;
  preview: string;
};

interface ConstraintInterface {
  width?: number;
  height?: number;
  facingMode?: string | { exact: string };
}

interface WebcamCaptureProps {
  showWebCamModal: boolean;
  setShowWebCamModal: (showWebCamModal: boolean) => void;
  setCapturedImage: (capturedImage: FileProps | null) => void;
  capturedImage: string;
  cancelCameraRef: React.MutableRefObject<HTMLElement | null>;
  handleSave: () => void;
  videoConstraints?: ConstraintInterface;
}

const WebcamCapture = ({
  setCapturedImage,
  showWebCamModal,
  setShowWebCamModal,
  capturedImage,
  handleSave,
  cancelCameraRef,
  videoConstraints = userConstraints,
}: WebcamCaptureProps) => {
  const { translate } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const capture = useCallback(() => {
    const imageSrc =
      webcamRef.current !== null ? webcamRef.current.getScreenshot() : null;
    setCapturedImage({
      file: imageSrc as string,
      preview: imageSrc as string,
      method: 'selfie',
    });
  }, [webcamRef]);

  let content;

  if (capturedImage) {
    content = (
      <div
        className="rounded-circle"
        style={{
          width: `${videoConstraints.width}`,
          height: `${videoConstraints.height}`,
        }}
      >
        <img src={capturedImage} alt="" className="rounded-circle" />
      </div>
    );
  } else if (showWebCamModal === true) {
    content = (
      <Webcam
        audio={false}
        height={videoConstraints.height}
        ref={webcamRef}
        screenshotFormat="image/png"
        width={videoConstraints.width}
        videoConstraints={videoConstraints}
        style={{ borderRadius: '999px' }}
        screenshotQuality={1}
        onUserMediaError={(e) => {
          Sentry.captureException(e);
          setShowWebCamModal(false);
          const errorMessage = (
            <div>
              {translate('invitation-web-app-take-pic-access')}
              <a
                href={
                  isMobile
                    ? 'https://youtu.be/lqa8H6vXRg8'
                    : 'https://youtu.be/guv6kkVcxdU'
                }
                rel="noreferrer"
                target="_blank"
              >
                {' '}
                {translate('invitation-web-app-take-pic-reason')}
              </a>{' '}
              {translate('invitation-web-app-take-pic-reason-2')}
            </div>
          );
          toast.error(errorMessage, {
            id: 'camera-error',
          });
        }}
      />
    );
  } else {
    content = <div>{translate('invitation-web-app-take-pic-permission')}</div>;
  }

  return (
    <AlertDialog
      isOpen={showWebCamModal}
      leastDestructiveRef={cancelCameraRef}
      onClose={async () => {
        setShowWebCamModal(false);
        setCapturedImage(null);
      }}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent w="90%">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color={'#24C6A1'}>
            {translate('invitation-web-app-take-pic')}
          </AlertDialogHeader>

          <AlertDialogBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: `250px` }}
          >
            {content}
          </AlertDialogBody>

          <AlertDialogFooter
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {!capturedImage && (
              <Button
                variant="green"
                style={{ width: 'fit-content', minWidth: '80px' }}
                onClick={() => capture()}
                bg="#24C6A1"
              >
                {capturedImage
                  ? translate('invitation-web-app-upload')
                  : translate('invitation-web-app-capture')}
              </Button>
            )}
            {capturedImage && (
              <>
                <Button
                  variant="green"
                  style={{ width: 'fit-content', minWidth: '80px' }}
                  onClick={() => handleSave()}
                  bg="#24C6A1"
                >
                  {capturedImage
                    ? translate('invitation-web-app-upload')
                    : translate('invitation-web-app-capture')}
                </Button>
                <Button
                  marginRight="1rem"
                  variant="outlined"
                  onClick={() => {
                    setCapturedImage(null);
                  }}
                >
                  {translate('invitation-web-app-retake')}
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

WebcamCapture.propTypes = {
  setCapturedImage: PropTypes.func.isRequired,
  showWebCamModal: PropTypes.bool.isRequired,
  setShowWebCamModal: PropTypes.func.isRequired,
  capturedImage: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default WebcamCapture;
